import { FC } from "react";
import { NewsIface } from "interface/NewsIface";

const NewsTitle: FC<{ news: NewsIface }> = ({ news }) => {
  const titleNewsIcon = require("assets/img/title_news_icon.png");

  return (
    <div className="flex sm:block xl:px-[188px] lg:px-[120px] md:px-[60px] sm:bg-white  bg-[#F2F4F8] sm:w-full">
      <div className="flex items-center mr-8 sm:m-0 min-w-[400px] max-w-[400px] sm:max-w-full sm:min-w-full">
        <img src={news.thumbnail} alt="news" className="w-full" />
      </div>
      <div className="flex sm:mx-4   flex-col mt-8 sm:mt-4">
        <div>
          <img src={titleNewsIcon} alt="icon" />
        </div>
        <div className="text-2xl sm:text-lg font-bold mt-3 mb-8 sm:mb-2 leading-7">
          {news.title}
        </div>
        <span className="text-xs text-[#545B78]">{news.date}</span>
      </div>
    </div>
  );
};

export default NewsTitle;
