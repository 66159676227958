interface game {
  url: string;
  name: string;
  logo: string;
}

export const newsCategories = [
  { id: "54109", name: "Tất cả" },
  { id: "1", name: "Thông báo" },
  { id: "14328", name: "Tiến lên miền nam" },
  {
    id: "15417",
    name: "Tá Lả",
  },
  {
    id: "15696",
    name: "Mậu binh",
  },
  {
    id: "43313",
    name: "Crazy Tiến Lên",
  },
  {
    id: "43445",
    name: "Cờ Tướng",
  },
];

export const gameList: game[] = [
  {
    url: "https://www.play.zing.vn/zp/tlmn?utm_source=Website&utm_medium=Header-playzing&utm_campaign=tabthaxuong ",
    name: "Tiến lên miền nam",
    logo: require("../assets/img/TLMN.png"),
  },
  {
    url: "https://www.play.zing.vn/zp/tala?utm_source=Website&utm_medium=Header-playzing&utm_campaign=tabthaxuong",
    name: "Tá Lả",
    logo: require("../assets/img/TaLa.png"),
  },
  {
    url: "https://www.play.zing.vn/zp/maubinh?utm_source=Website&utm_medium=Header-playzing&utm_campaign=tabthaxuong",
    name: "Mậu Binh",
    logo: require("../assets/img/MB.png"),
  },
  {
    url: "https://www.play.zing.vn/zp/crazytl?utm_source=Website&utm_medium=Header-playzing&utm_campaign=tabthaxuong",
    name: "Crazy Tiến Lên",
    logo: require("../assets/img/CZTienLen.jpg"),
  },
  {
    url: "https://www.play.zing.vn/zp/cotuong?utm_source=Website&utm_medium=Header-playzing&utm_campaign=tabthaxuong",
    name: "Cờ Tướng",
    logo: require("../assets/img/CoTuong.jpg"),
  },
];

export const KHO_GAME: game[] = [
  {
    url: "https://www.play.zing.vn/zp/tlmn?utm_source=Website&utm_medium=khogame-playzing&utm_campaign=khogameplayzing",
    name: "Tiến lên miền nam",
    logo: require("../assets/img/TLMN_thumbnail.png"),
  },
  {
    url: "https://www.play.zing.vn/zp/tala?utm_source=Website&utm_medium=khogame-playzing&utm_campaign=khogameplayzing",
    name: "Tá Lả",
    logo: require("../assets/img/TaLa_thumbnail.png"),
  },
  {
    url: "https://www.play.zing.vn/zp/maubinh?utm_source=Website&utm_medium=khogame-playzing&utm_campaign=khogameplayzing",
    name: "Mậu Binh",
    logo: require("../assets/img/MauBinh_thumbnail.png"),
  },
  {
    url: "https://www.play.zing.vn/zp/crazytl?utm_source=Website&utm_medium=khogame-playzing&utm_campaign=khogameplayzing",
    name: "Crazy Tiến Lên",
    logo: require("../assets/img/CrazyTL_thumbnail.png"),
  },
  {
    url: "https://www.play.zing.vn/zp/cotuong?utm_source=Website&utm_medium=khogame-playzing&utm_campaign=khogameplayzing",
    name: "Cờ Tướng",
    logo: require("../assets/img/CoTuong_thumbnail.png"),
  },
];
