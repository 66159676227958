import './style.css'

export const Loading = () => {
  return (
    <div className="loading">
      <div className="loading-wrap">
        <div className="loader">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    </div>
  );
};
