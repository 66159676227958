import { Loading } from "components/Loading/Loading";
import Container from "./Container";
import { useEffect } from "react";
import { checkShowCookieConsent } from "utils/common";

const HomeIndex = () => {
  useEffect(() => {
    checkShowCookieConsent();
  }, []);
  
  return (
    <div>
      <Loading />
      <Container></Container>
    </div>
  );
};

export default HomeIndex;
