import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { AppContenxt } from "context/AppContext";
import { gameList, newsCategories } from "helper/constants";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  faAngleDown,
  faAngleUp,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

const Line = () => {
  return <div className=" h-px my-4 bg-[#E6EAED]"></div>;
};

export const LeftMenu: FC<{
  onShow: boolean;
  onHide: () => void;
  beforeExit?: (cb: () => void) => void;
}> = ({ onShow, onHide, beforeExit }) => {
  const logo = require("assets/img/logo.png");
  const navigate = useNavigate();

  const { newsCategory, setNewsCategory } = useContext(AppContenxt);
  const location = useLocation();

  const [showNewsCategories, setShowNewsCategories] = useState(false);

  const handleChoiceNewsCategory = useCallback(
    (gameId: string) => {
      console.log(location.pathname);
      if (location.pathname !== "/news") {
        console.log(123123);
        navigate("/news");
      }
      setNewsCategory(gameId);
      onHide();
    },

    [location.pathname, navigate, onHide, setNewsCategory]
  );

  const goToLink = (link: string) => {
    if (beforeExit) {
      beforeExit(() => {
        navigate(link);
      });
    } else navigate(link);
  };

  useEffect(() => {
    const el = document.getElementById("logo");
    if (!el) return;
    if (onShow) {
      setTimeout(() => {
        el.style.visibility = "visible";
      }, 35);
    } else {
      el.style.visibility = "hidden";
    }
  }, [onShow]);

  return (
    <div
      className={classNames(
        "z-10 fixed h-screen top-0 left-0  w-screen",
        onShow ? "visible opacity-100" : "invisible opacity-0"
      )}
    >
      <div id="overlay" className="bg-black opacity-75 w-full h-full"></div>
      <div className={classNames("absolute top-0 left-0 flex h-full  w-full")}>
        <div
          className={classNames(
            "bg-white relative pt-3 px-6  overflow-hidden duration-100  ease-in-out",
            onShow ? "flex-[4]" : "w-0"
          )}
        >
          <div
            className={classNames(
              "fixed items-center overflow-hidden duration-1000 ease-in-out"
            )}
            id="logo"
            onClick={() => {
              goToLink("/");
            }}
          >
            <img src={String(logo)} alt="Logo" />
          </div>
          <div className="absolute top-[16%] w-[90%]">
            <div
              className="font-bold text-clip whitespace-nowrap "
              onClick={() => {
                goToLink("/");
              }}
            >
              Trang chủ
            </div>

            <Line />

            <div
              className="font-bold text-clip whitespace-nowrap flex items-center justify-between mb-2"
              onClick={() => {
                setShowNewsCategories((prev) => !prev);
              }}
            >
              <span>Tin tức - sự kiện</span>
              <div>
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className={`icon ${
                    showNewsCategories ? "rotate-down" : "rotate-up"
                  } group-hover:opacity-0`}
                />
              </div>
            </div>

            <ul
              className="overflow-hiden"
              style={{
                transition: "all linear 0.3s",
                maxHeight: showNewsCategories ? "500px" : "0",
                overflow: "hidden",
              }}
            >
              {newsCategories.map((val, index) => (
                <li
                  key={index}
                  className="text-left py-2.5 px-2.5 divide-y divide-slate-200 hover:bg-gray-100"
                  style={{
                    background: newsCategory === val.id ? "#ccc" : "white",
                    borderBottom:
                      index < newsCategories.length - 1
                        ? "1px solid #E6EAED"
                        : "",
                  }}
                  onClick={() => handleChoiceNewsCategory(val.id)}
                >
                  <span className="ml-2 block my-auto">{val.name}</span>
                </li>
              ))}
            </ul>

            <Line />

            {/* <div
              className="font-bold text-clip whitespace-nowrap "
              onClick={() => {
                goToLink("/event");
              }}
            >
              Đố Vui Nhận Quà
            </div>

            <Line /> */}
            <div className="font-bold text-clip whitespace-nowrap mb-4">
              Chơi ngay
            </div>
            {gameList.map((game, idx) => {
              return (
                <React.Fragment key={idx}>
                  <div className="flex overflow-hidden   ">
                    <a
                      href={game.url}
                      target="_blank"
                      rel="noreferrer"
                      className="flex"
                    >
                      <img
                        src={game.logo}
                        alt="Game Logo"
                        className="bg-cover w-8 h-8"
                      />
                      <span className="ml-2 block my-auto font-bold text-clip whitespace-nowrap">
                        {game.name}
                      </span>
                    </a>
                  </div>
                  {idx < gameList.length - 1 && <Line />}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="flex-[1] " onClick={onHide}>
          <FontAwesomeIcon
            icon={faTimes}
            className="text-white cursor-pointer  ml-[25%] font-thin mt-16 w-[32px] h-[32px]"
          />
        </div>
      </div>
    </div>
  );
};
