import { Complaint } from "pages/Complaint/ComplaintIndex";
import HomeIndex from "pages/HomePage/HomeIndex";
import NewsIndex from "pages/News/NewsIndex";
import NewsDetailsIndex from "pages/NewsDetail/NewsDetailIndex";
import PageNotFoundIndex from "pages/PageNotFound/PageNotFoundIndex";
import { Privacy } from "pages/Privacy/PrivacyIndex";
import { Terms } from "pages/Terms/TermsIndex";

export const Routers = [
  {
    path: "*",
    component: () => <PageNotFoundIndex />,
  },
  {
    path: "/",
    component: () => <HomeIndex />,
  },
  {
    path: "/term",
    component: () => <Terms />,
  },
  {
    path: "/static-term",
    component: () => <Terms />,
  },
  {
    path: "/news",
    component: () => <NewsIndex />,
  },
  {
    path: "/news/:path/:postId",
    component: () => <NewsDetailsIndex />,
  },
  {
    path: "/privacy",
    component: () => <Privacy />,
  },
  {
    path: "/static-privacy",
    component: () => <Privacy />,
  },
  {
    path: "/complaint",
    component: () => <Complaint />,
  },
  {
    path: "/static-complaint",
    component: () => <Complaint />,
  },
];
