import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KhoGame } from "components/KhoGame/KhoGame";
import { Link } from "react-router-dom";
import News from "../../components/News";

const Container = () => {
  return (
    <>
      <div className="mt-[70px] sm:m-top[16px] xl:mx-[188px] lg:mx-[120px] md:mx-[60px]  sm:mt-4 sm:mx-4 ">
        {/* content */}
        <News
          height={""}
          color={"text-[#545B78]"}
          titleColor={"text-black"}
        ></News>
        <Link
          to={"/news"}
          className="mb-12 flex justify-center items-center rounded-3xl mx-auto h-12 w-[136px] bg-[linear-gradient(90deg,_#57A6FF_0%,_#54CFFF_102.57%)] text-white"
        >
          Xem thêm
          <FontAwesomeIcon
            className="ml-1 animate-shift-left"
            icon={faAngleDoubleRight}
          ></FontAwesomeIcon>
        </Link>
      </div>
      <KhoGame />
    </>
  );
};

export default Container;
