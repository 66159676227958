export const checkShowCookieConsent = () => {
  let pathName = window.location.pathname;
  if (
    !pathName.endsWith("/term") &&
    !pathName.endsWith("/privacy") &&
    !pathName.endsWith("/complaint")
  ) {
    window?.ZGConsent?.showCookieConsentIfNeeded({
      consentType: "zingplay",
      termUrl: "https://www.play.zing.vn/term",
      policyUrl: "https://www.play.zing.vn/privacy",
    });
  }
};
