import { NewsIface } from "interface/NewsIface";
import { FC } from "react";
import { Link } from "react-router-dom";

export const NewsItemRow: FC<{
  val: NewsIface;
  textColorClass?: string;
  titleColor?: string;
}> = ({
  val,
  textColorClass = "text-white",
  titleColor = "text-white",
}) => {
    return (
      <div className="overflow-hidden ">
        <div className="flex h-full">
          <Link to={`/news/${val.path}/${val.postId}`} className="w-1/2 " >
            <img src={val.thumbnail} alt="News" className="rounded" loading="lazy" />
          </Link>
          <div
            className="ml-3 flex flex-col"
            style={{
              width: "calc(50% - 0.75rem)",
            }}
          >
            <span className={`text-xs pb-1 ${textColorClass}`}>{val.date}</span>
            <Link
              to={`/news/${val.path}/${val.postId}`}
              className={`font-bold overflow-hidden align-justify text-ellipsis ${titleColor}`}
            >
              {val.title}
            </Link>
            <span
              className={`mt-2 text-sm overflow-hidden text-ellipsis leading-6 ${textColorClass}`}
              style={{
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                MozBoxOrient: "vertical",
                boxOrient: "vertical",
                lineClamp: 2,
              }}
            >
              {val.description}
            </span>
          </div>
        </div>
      </div>
    );
  };
