import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Routers } from "config/routers";
import Header from "components/Header";
import Footer from "components/Footer";

function App() {
  const { pathname } = window.location;
  console.log(pathname);
  const justContent = pathname?.includes("static");
  return (
    <Router>
      {/* {!inEventPage && <ShowEventPopup />} */}

      {!justContent && <Header />}

      <Routes>
        {/* <Route path="*" element={<PageNotFoundIndex />}></Route>
            <Route path="/" element={<HomeIndex />} /> */}
        {/* <Route
                path="/event"
                element={
                <EventTetProvider>
                    <EventIndex />
                </EventTetProvider>
                }
            /> */}

        {/* <Route path="/terms" element={<Terms />} />
            <Route path="/news">
                <Route index element={<NewsIndex />}></Route>
                <Route
                path="/news/:path/:postId"
                element={<NewsDetailsIndex />}
                ></Route>
            </Route> */}

        {Routers.map((el, idx) => (
          <Route
            key={idx}
            path={el.path}
            element={
              <div
                className="body-style"
                style={{ marginTop: justContent ? "20px" : "90px" }}
              >
                <el.component />
              </div>
            }
          ></Route>
        ))}
      </Routes>
      {!justContent && <Footer />}
    </Router>
  );
}

export default App;
