import { get } from "api/api";
import { NewsIface } from "interface/NewsIface";
import { UserStatusIface } from "interface/UserStatusIFace";
const apiNewsUrl = process.env.REACT_APP_API_DOPMAIN;
const GAME_ID = 54109;
const parseTime = (timestamp: number): string => {
  const d = new Date(timestamp);
  let month = d.getMonth() + 1;

  let res = "";
  if (d.getDate() < 10) {
    res += "0" + d.getDate();
  } else {
    res += d.getDate();
  }
  res += "/";
  if (month < 10) {
    res += "0" + month;
  } else {
    res += month;
  }
  res += "/" + d.getFullYear();

  return res;
};

export async function getNewsList(id: number, from: number, count: number = 9) {
  const apiUrl = apiNewsUrl + "post";
  const res = await get<NewsIface[]>(apiUrl, {
    gameId: id,
    from,
    count,
    public: true,
  });
  if (res.data) {
    for (const news of res.data) {
      news.date = parseTime(news.time);
    }
  }
  return res;
}

export async function getOneNews(postId: number) {
  const apiUrl = apiNewsUrl + "post";

  const res = await get<NewsIface>(apiUrl, {
    public: true,
    postId,
    gameId: GAME_ID,
  });
  if (res.data) {
    res.data.date = parseTime(res.data.time);
  }
  return res;
}

export async function getUserStatus(zgsk: String) {
  const apiUrl = "http://localhost:9031/api/get-user-status";
  const res = await get<UserStatusIface>(apiUrl, {
    zgsk: zgsk,
  });

  return res;
}
