import { Loading } from "components/Loading/Loading";
import Container from "./Container";
import { useEffect } from "react";
import { checkShowCookieConsent } from "utils/common";

const NewsIndex = () => {
  useEffect(() => {
    checkShowCookieConsent();
  }, []);

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Loading />
      <Container></Container>
    </div>
  );
};

export default NewsIndex;
