import { useEffect } from "react";
import "./style.css";
import { checkShowCookieConsent } from "utils/common";
export const Complaint = () => {
  useEffect(() => {
    checkShowCookieConsent();
  }, []);
  
  useEffect(() => {
    const event = document.getElementById("complaint");
    event?.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  return (
    <div id="complaint">
      <div className="sm:px-4 page-content max-w-[800px] mx-auto">
        <div className="mt-3 truncate pb-0.5 clear-both">
          <h3
            className="float-left font-bold text-lg text-left truncate indent-0"
            style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
          >
            Quy Trình Giải Quyết Khiếu Nại
          </h3>
        </div>
        <p>
          <strong>Bước 1: Tiếp nhận khiếu nại.</strong>
        </p>

        <p>
          Đối tượng khiếu nại: Là khách hàng sử dụng sản phẩm, dịch vụ có liên
          quan trực tiếp đến nội dung khiếu nại
        </p>

        <p>Phạm vi khiếu nại: Dịch vụ mà khách hàng bị ảnh hưởng</p>

        <p>Thời hiệu khiếu nại: 03 tháng kể từ ngày xảy ra sự việc.</p>

        <p>
          Nơi tiếp nhận: Khiếu nại có thể được thực hiện qua các hình thức sau:
        </p>

        <p>
          <li>Trung tâm hỗ trợ khách hàng của từng game</li>

          <li>
            Website:{" "}
            <a href="hotro.zagoo.vn">
              <strong>hotro.zagoo.vn</strong>
            </a>
          </li>
        </p>

        <p>
          Trường hợp loại hình khiếu nại có ghi cụ thể hình thức thì áp dụng
          theo hình thức đó.
        </p>

        <p>
          <li>
            Khi tiếp nhận khiếu nại: Bộ phận hỗ trợ khách hàng có trách nhiệm
            gửi thông tin xác nhận việc tiếp nhận khiếu nại.
          </li>
          <li>
            Khách hàng phải cung cấp các hồ sơ, tài liệu có liên quan theo yêu
            cầu của Bộ phận hỗ trợ khách hàng.
          </li>
        </p>

        <p>
          <strong>Bước 2: Phân tích, đánh giá sơ bộ.</strong>
        </p>
        <p>
          Sau khi tiếp nhận, Bộ phận hỗ trợ khách hàng có thể gặp gỡ, tiếp xúc,
          trao đổi với khách hàng về các vấn đề liên quan tạo điều kiện cho quá
          trình xử lý, hỗ trợ khách hàng
        </p>
        <p>
          <strong>Bước 3: Trao đổi bộ phận liên quan.</strong>
        </p>

        <p>
          <strong>Bước 4: Tổng hợp thông tin.</strong>
        </p>

        <p>
          <strong>Bước 5: Trả lời khách hàng.</strong>
        </p>

        <p>
          Thời hạn trả lời: 10 ngày làm việc kể từ ngày tiếp nhận khiếu nại.
          Trường hợp các sản phẩm, chương trình, dịch vụ có quy định thời hạn
          giải quyết khiếu nại riêng thì áp dụng theo quy định đó.
        </p>
        <p>Nguyên tắc trả lời:</p>

        <p>
          <li>
            Tùy từng trường hợp trả lời theo các hình thức khác nhau: Email, Văn
            bản, Điện thoại, Zalo Official Account của từng game
          </li>
          <li>Trả lời cho người khiếu nại</li>
        </p>

        <p>
          <strong>Bước 6: Bổ sung khiếu nại.</strong>
        </p>
        <p>
          Việc tiếp nhận, giải quyết khiếu nại bổ sung sẽ tuân theo các quy
          định, quy trình như khiếu nại mới.
        </p>
        <p>
          Khi phát sinh khiếu nại bổ sung thì thời hạn trả lời được tính lại từ
          đầu.
        </p>
        <p>
          <strong>Bước 7: Kết thúc.</strong>
        </p>

        <div className="mt-3 truncate pb-0.5 clear-both">
          <h3
            className="float-left font-bold text-lg text-left truncate indent-0"
            style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
          >
            QUY TRÌNH XỬ LÝ DỮ LIỆU CÁ NHÂN
          </h3>
        </div>

        <p>
          <strong>Bước 1: Tiếp nhận yêu cầu.</strong>
        </p>
        <p>
          Đối tượng yêu cầu: Là khách hàng sử dụng sản phẩm, dịch vụ của Long
          Đỉnh hoặc người đại diện pháp luật hợp pháp/ người giám hộ của khách
          hàng sử dụng (trong trường hợp người dùng chưa đủ 15 tuổi)
        </p>

        <p>Phạm vi yêu cầu xử lý dữ liệu cá nhân bao gồm:</p>

        <p>
          <li>
            Yêu cầu xem và xuất thông tin cá nhân bạn đồng ý cung cấp cho Long
            Đỉnh để có thể sử dụng các Dịch Vụ Của Long Đỉnh và/hoặc Công Ty
            Liên Kết với Long Đỉnh được quy định trong điều 4
          </li>
          <li>
            Yêu cầu rút lại sự đồng ý cho phép Long Đỉnh sử dụng các thông tin
            Bạn đã cung cấp để sử dụng các Dịch Vụ của Long Đỉnh và/hoặc Công Ty
            Liên Kết với Long Đỉnh được quy định trong điều 4
          </li>

          <li>
            Yêu cầu xóa thông tin bạn đồng ý cung cấp cho Long Đỉnh để có thể sử
            dụng các Dịch Vụ Của Long Đỉnh và/hoặc Công Ty Liên Kết với Long
            Đỉnh được quy định trong điều 4
          </li>
          <li>
            Lưu ý: Với các thông tin liên quan đến tài khoản ngân hàng, thông
            tin thanh toán liên quan đến thẻ tín dung/thẻ ghi nợ không được Long
            Đỉnh thu nhập hoặc lưu trữ (Quy định trong điều 6). Vì vậy, trong
            trường hợp người dùng yêu cầu các thông tin này, Long Đỉnh không thể
            cung cấp.
          </li>
        </p>
        <p>
          Hình thức gửi yêu cầu: Người dùng khi có yêu cầu xử lý dữ liệu cá
          nhân, người dùng vui lòng gửi yêu cầu đến địa chỉ email hỗ trợ của
          Long Đỉnh
        </p>

        <p>
          <li>
            Email: <a href="mailto: hotro@zagoo.vn"><strong>hotro@zagoo.vn</strong></a>
            
          </li>
          <li>
            Tiêu đề Mail: [NGÀY/THÁNG/NĂM][LONG ĐỈNH][YÊU CẦU XỬ LÝ DỮ LIỆU CÁ
            NHÂN]. Ví dụ: [09/12/2022][LONG ĐỈNH][YÊU CẦU XỬ LÝ DỮ LIỆU CÁ NHÂN]
          </li>
          <li>
            Nội dung Mail: Người dùng cung cấp các thông tin tương ứng theo mẫu
            số 1 của Nghị Định 13 và gửi về địa chỉ email hỗ trợ của Long Đỉnh.
            (Bấm vào liên kết này:{" "}
            <a href="https://thuvienphapluat.vn/van-ban/Cong-nghe-thong-tin/Nghi-dinh-13-2023-ND-CP-bao-ve-du-lieu-ca-nhan-465185.aspx">
              <strong>
                Nghị định 13/2023/NĐ-CP bảo vệ dữ liệu cá nhân mới nhất
                (thuvienphapluat.vn)
              </strong>
            </a>
          </li>
        </p>

        <p>
          <strong>Bước 2: Phân tích và xử lý yêu cầu</strong>
        </p>
        <p>
          Quy trình xử lý: Sau khi tiếp nhận, Bộ phận hỗ trợ khách hàng sẽ đánh
          giá về yêu cầu của khách hàng tương ứng với các điều khoản đã được nêu
          trên. Trong trường hợp, các thông tin yêu cầu từ khách hàng không phù
          hợp với các nội dung trong điều khoản hoặc Long Đỉnh không có dữ liệu
          liên quan cung cấp cho khách hàng. Bộ phận hỗ trơ khách hàng sẽ trực
          tiếp phản hồi qua email về lý do và hình thức xử lý
        </p>
        <p>
          Thời gian xử lý: Người dùng sẽ nhận được phản hồi từ phía Long Đỉnh
          trong vòng 10 ngày làm việc (Không tính thứ 7, CN) sau khi gửi email
          yêu cầu đến Long Đỉnh.
        </p>
        <p>
          <strong>Bước 3: Tổng hợp thông tin và hoàn thành yêu cầu</strong>
        </p>
        <p>
          Sau khi đã trao đổi và thống nhất các thông tin yêu cầu từ khách hàng,
          Bộ phận hỗ trợ khách hàng sẽ xử lý các dữ liệu cá nhân đã thống nhất
          cung cấp giữa hai bên qua email. Trong vòng 10 ngày làm việc nếu khách
          hàng không có yêu cầu hoặc ý kiến gì thêm, yêu cầu sẽ được hiểu là
          hoàn thành và đáp ứng nhu cầu của khách hàng.
        </p>
      </div>
    </div>
  );
};
