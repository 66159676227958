import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import ReactPaginate from "react-paginate";
export interface PaginationProps {
  handlePageClick: (event: { selected: number }) => void;
  totalItems?: number;
}
export const MyPagination: FC<PaginationProps> = ({
  handlePageClick,
  totalItems,
}) => {
  return (
    <ReactPaginate
      breakLabel="..."
      onPageChange={handlePageClick}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      pageCount={totalItems ?? 1}
      pageLinkClassName="page-item bg-[#f3f3f4]"
      previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
      nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
      previousLinkClassName="my-auto page-item bg-[#f3f3f4]"
      nextLinkClassName="my-auto page-item bg-[#f3f3f4]"
      breakLinkClassName="page-item"
      containerClassName="pagination flex "
      activeLinkClassName="bg-[#FFA557] text-white"
    />
  );
};
