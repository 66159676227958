import { ReactNode, createContext, useState } from "react";

export type AppContextType = {
  newsCategory: string;
  setNewsCategory: (cateogry: string) => void;
};

export const defaultValue = {
  newsCategory: localStorage.getItem("CATEGORY_ID") || "54109",
  setNewsCategory: () => {},
};

export const AppContenxt = createContext<AppContextType>(defaultValue);

export const AppContenxtProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [newsCategory, setNewsCategory] = useState(
    localStorage.getItem("CATEGORY_ID") || "54109"
  );

  return (
    <AppContenxt.Provider value={{ newsCategory, setNewsCategory }}>
      {children}
    </AppContenxt.Provider>
  );
};
