import { NewsItemRow } from "components/NewsItemRow";
import { AppContenxt } from "context/AppContext";
import { getNewsList } from "helper/api-helper";
import { NewsIface } from "interface/NewsIface";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";

const decodeHtmlEntities = (content: string) => {
  const text = document.createElement("textarea");
  text.innerHTML = content;
  return text.value;
};
export const Content: FC<{
  news: NewsIface;
}> = ({ news }) => {
  const [newsList, setNewsList] = useState<NewsIface[]>();
  const { newsCategory } = useContext(AppContenxt);

  const fetch = useCallback(async () => {
    try {
      const res = await getNewsList(
        newsCategory === "All" ? -1 : +newsCategory,
        0,
        4
      );
      if (res.error_code === 0) {
        setNewsList(res.data);
      } else {
        alert(res.message);
      }
    } catch (error) {
      alert(error);
    }
  }, [newsCategory]);
  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <div className="flex mt-6  sm:block xl:pl-[188px] lg:pl-[120px] md:pl-[60px]  sm:block xl:pr-[155px] lg:pr-[58px] md:pr-[30px]">
      <div
        id="detail"
        className="text-align flex-[6] mr-14 sm:mr-0"
        dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(news.content) }}
      ></div>
      <div className="flex-[3]">
        <div className="hidden sm:block w-full h-px my-4 bg-[#E6EAED]"></div>
        <div className="text-lg font-bold mt-3 mb-8 sm:mb-2">
          Tin tức - sự kiện khác
        </div>
        <div className=" flex flex-col justify-between mb-6">
          {newsList?.map((val, index) => {
            return (
              <React.Fragment key={index}>
                <NewsItemRow
                  val={val}
                  titleColor="text-black"
                  textColorClass="text-[#545B78]"
                />
                {index < newsList.length - 1 && (
                  <div className="w-full h-px my-4 bg-[#E6EAED]"></div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
