import { FC } from "react";
import { Link } from "react-router-dom";

const Footer:FC = () => {
  return (
    <section className="sm:px-4 bg-[linear-gradient(90deg,_#57A6FF_0%,_#0086BA_102.57%)] w-full text-white text-lg sm:text-xs">
      <div
        className="py-5 flex flex-col justify-center items-center w-full"
        // style={{
        //   maxWidth: "1000px",
        // }}
      >
        <div className="self-left">
          Copyright © Zing, Đơn vị chủ quản: Công ty Cổ phần VNG.
        </div>
        <div>
          Giấy phép phê duyệt nội dung số: 03/QĐ-TCTT cấp ngày 16/1/2009 do Bộ
          Thông Tin Và Truyền Thông cấp.
        </div>
        <div className="w-full flex justify-center items-center gap-[1%]">
          <Link
            className="text-[#ff0000] font-bold mt-2 block"
            to="/term"
            title="Điều khoản sử dụng"
          >
            Điều khoản sử dụng
          </Link>
          <p>|</p>
          <Link
            className="text-[#ff0000] font-bold mt-2 block"
            to="/privacy"
            title="Chính sách bảo mật"
          >
            Chính sách bảo mật
          </Link>
          <p>|</p>
          <Link
            className="text-[#ff0000] font-bold mt-2 block"
            to="/complaint"
            title="Quy trình khiếu nại"
          >
            Quy trình khiếu nại
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Footer