import {
  faAngleDown,
  faAngleUp,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gameList, newsCategories } from "helper/constants";
import React, {
  FC,
  LegacyRef,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LeftMenu } from "./LeftMenu";
import { AppContenxt } from "context/AppContext";

const CATEGORY_ID = "CATEGORY_ID";
const Header: FC<{
  beforeExit?: (cb: () => void) => void;
}> = ({ beforeExit }) => {
  const adultLogo = require("../assets/img/adult_logo.png");
  const logo = require("../assets/img/logo.png");
  const [openLeftMenu, setOpenLeftMenu] = useState(false);

  const { newsCategory, setNewsCategory } = useContext(AppContenxt);

  const categoryRef = useRef<HTMLElement | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const goToLink = (link: string) => {
    if (beforeExit) {
      beforeExit(() => {
        navigate(link);
      });
    } else navigate(link);
  };

  const handleChoiceNewsCategory = useCallback(
    (gameId: string) => {
      if (!location.pathname?.includes("/news")) {
        navigate("/news");
      }
      setNewsCategory(gameId);
      localStorage.setItem(CATEGORY_ID, gameId);
      if (categoryRef.current) {
        categoryRef.current?.setAttribute("style", "display:none");
        setTimeout(() => {
          categoryRef.current?.setAttribute("style", "display:block");
        }, 300);
      }
    },
    [location, navigate, setNewsCategory]
  );
  return (
    <div className="header sm:h-[60px] lg:h-[90px]">
      <nav className="flex justify-between h-full">
        <ul className="flex">
          <img src={String(adultLogo)} alt="Adult Logo" className="" />
          {/* <Link to={"/"} className="flex h-full my-auto ml-6 items-center">
            <img src={String(logo)} alt="Logo" className="sm:h-11" />
          </Link> */}
          <div
            className="flex h-full my-auto ml-6 items-center"
            onClick={() => {
              goToLink("/");
            }}
          >
            <img
              src={String(logo)}
              alt="Logo"
              className="sm:h-11 cursor-pointer"
            />
          </div>
        </ul>
        <ul className="sm:hidden flex my-auto mr-76 font-medium text-center">
          {/* <li className="mx-8 py-2">
            <div
              className="cursor-pointer"
              onClick={() => {
                goToLink("/event");
              }}
            >
              Đố Vui Nhận Quà
            </div>
          </li> */}

          <li
            className="cursor-pointer mx-8 py-2 flex items-center justify-center"
            onClick={() => {
              goToLink("/");
            }}
          >
            Trang chủ
          </li>
          <li className="mx-8 py-2">
            {/* <div
              className="cursor-pointer"
              onClick={() => {
                goToLink("/news");
              }}
            >
              Tin tức - sự kiện
            </div> */}
            <li className="mx-8 py-2 flex justify-center align-center relative group hover:cursor-pointer">
              <span>Tin tức - Sự kiện</span>
              <FontAwesomeIcon
                icon={faAngleUp}
                className="ml-2  my-auto group-hover:invisible group-hover:opacity-0 group-hover:hidden"
              />
              <FontAwesomeIcon
                icon={faAngleDown}
                className="ml-2 my-auto opacity-0 group-hover:visible group-hover:opacity-100 invisible hidden group-hover:block"
              />

              <div
                ref={categoryRef as any}
                className="overflow-hidden ease-in-out duration-200 block opacity-0 invisible group-hover:visible group-hover:opacity-100 absolute right-0 top-8 bg-white z-10 w-[200px] shadow-lg rounded"
              >
                <ul className="">
                  {/* <li className="text-left py-2 px-2.5 divide-y divide-slate-200 hover:bg-gray-100">
                    <span className="ml-2 block my-auto">Tất cả</span>
                  </li>
                  <div className="w-fu  ll h-px my-1 bg-[#E6EAED]"></div> */}

                  {newsCategories.map((val, index) => (
                    <li
                      key={index}
                      className="text-left py-2.5 px-2.5 divide-y divide-slate-200 hover:bg-gray-100"
                      style={{
                        background: newsCategory === val.id ? "#ccc" : "white",
                        borderBottom:
                          index < newsCategories.length - 1
                            ? "1px solid #E6EAED"
                            : "",
                      }}
                      onClick={() => handleChoiceNewsCategory(val.id)}
                    >
                      <span className="ml-2 block my-auto">{val.name}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          </li>
          <li className="mx-8 py-2 flex justify-center items-center align-center relative group hover:cursor-pointer">
            <span>Chơi ngay</span>
            <FontAwesomeIcon
              icon={faAngleUp}
              className="ml-2  my-auto group-hover:invisible group-hover:opacity-0 group-hover:hidden"
            />
            <FontAwesomeIcon
              icon={faAngleDown}
              className="ml-2 my-auto opacity-0 group-hover:visible group-hover:opacity-100 invisible hidden group-hover:block"
            />

            <div className=" ease-in-out duration-200 block opacity-0 invisible group-hover:visible group-hover:opacity-100 absolute right-0 top-8 bg-white z-10 w-[200px] shadow-lg rounded">
              <ul className="">
                {gameList.map((val, index) => (
                  <React.Fragment key={index}>
                    <li className="py-2 px-2.5 divide-y divide-slate-200 hover:bg-gray-100">
                      <a
                        href={val.url}
                        target="_blank"
                        rel="noreferrer"
                        className="flex"
                      >
                        <img
                          src={val.logo}
                          alt="Game Logo"
                          className="bg-cover w-8 h-8 rounded-md"
                        />
                        <span className="ml-2 block my-auto">{val.name}</span>
                      </a>
                    </li>

                    {index < gameList.length - 1 && (
                      <div className="w-full h-px my-1 bg-[#E6EAED]"></div>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </li>
        </ul>
        <ul
          onClick={() => setOpenLeftMenu(true)}
          className="hidden sm:flex top-0 right-0 absolute h-[60px] pr-[21px] text-2xl my-auto items-center"
        >
          <FontAwesomeIcon icon={faBars} />
        </ul>
      </nav>
      <div className="w-full h-px opacity-30 bg-[linear-gradient(90deg,_#FFDA57_0%,_#FF7354_102.57%)]"></div>
      <LeftMenu
        beforeExit={beforeExit}
        onShow={openLeftMenu}
        onHide={() => setOpenLeftMenu(false)}
      />
    </div>
  );
};

export default Header;
