import { useEffect } from 'react';
import './style.css'
import { checkShowCookieConsent } from 'utils/common';

const PageNotFoundIndex = () => {
    useEffect(() => {
        checkShowCookieConsent();
    }, []);

    return(
        <div>
            <div className="bg-[#f4f4f4] text-[#bbb] h-[calc(100vh_-_90px_-_132px_-_1px)] sm:h-[calc(100vh_-_60px_-_112px_-_1px)]">
                <h1 className="font-bold text-4xl sm:text-xl pt-16 sm:pt-8 text-center">404 Page Not Found!</h1>
                <section className="error-container text-[180px] sm:text-[120px]">
                    <span><span>4</span></span>
                    <span>0</span>
                    <span><span>4</span></span>
                </section>
            </div>
        </div>
    )
}

export default PageNotFoundIndex