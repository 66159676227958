import { FC, useEffect, useState } from "react";
import { NewsIface } from "interface/NewsIface";
import NewsTitle from "components/NewsDetail/Title";
import { Content } from "components/NewsDetail/Content";
import { useParams } from "react-router-dom";
import { getOneNews } from "helper/api-helper";

export const Container: FC = () => {
  const [news, setNews] = useState<NewsIface>();
  const params = useParams();

  const { postId } = params;
  useEffect(() => {
    const loading = document.querySelector(".loading") as HTMLDivElement;
    loading.classList.remove("is-hide", "hidden");
    const fetch = async () => {
      if (postId) {
        const res = await getOneNews(Number(postId));
        if (res.error_code === 0) {
          setNews(res.data);
          window.scrollTo({
            left: 0,
            top: 0,
          });

          if (loading) {
            setTimeout(() => {
              loading.classList.add("is-hide", "hidden");
            }, 200);
          }
        }
      }
    };
    fetch();
  }, [postId]);

  return news ? (
    <>
      <NewsTitle news={news} />
      <div className=" sm:mx-4">
        <Content news={news} />
      </div>
    </>
  ) : null;
};
