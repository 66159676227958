import React, { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NewsIface } from "../interface/NewsIface";
import classnames from "classnames";
import { NewsItemRow } from "./NewsItemRow";
import { getNewsList } from "helper/api-helper";
import { AppContenxt } from "context/AppContext";

const News: FC<{ height: string; color: string; titleColor?: string }> = ({
  height,
  color,
  titleColor = "text-black",
}) => {
  const [newsList, setNewsList] = useState<NewsIface[]>();
  const { newsCategory } = useContext(AppContenxt);

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await getNewsList(
          newsCategory === "All" ? -1 : +newsCategory,
          0,
          4
        );
        if (res.error_code === 0) {
          setNewsList(res.data);

          const loading = document.querySelector(".loading") as HTMLDivElement;
          if (loading) {
            setTimeout(() => {
              loading.classList.add("is-hide");
              loading.classList.add("hidden");
            }, 400);
          }
        } else {
          alert(res.message);
        }
      } catch (error) {
        alert(error);
      }
    };
    fetch();
  }, [newsCategory]);

  const titleNewsIcon = require("../assets/img/title_news_icon.png");
  const heightSize = height === "news" ? "" : "";
  return newsList && newsList.length > 0 ? (
    <div
      className={classnames(
        "flex md:justify-between sm:flex-col pb-[56px]",
        heightSize
      )}
    >
      <div className="flex-[5] sm:block text-white sm:text-black flex lg:mr-10 md:mr-8 xl:mr-10  sm:mr-0 sm:w-full ">
        <div className="flex relative sm:static sm:flex-col">
          <Link
            to={"/news/" + newsList[0].path + "/" + newsList[0].postId}
            className="w-full h-full"
          >
            <img
              src={newsList[0].thumbnail}
              alt="News"
              className="w-full h-full rounded-lg"
            />
          </Link>
          <div
            className="overlay absolute sm:hidden bottom-0  h-1/2 w-full rounded-bl-lg rounded-br-lg"
            style={{
              background:
                "linear-gradient(179.56deg, rgba(0, 0, 0, 0) 66.21%, #000000 99.62%)",
            }}
          ></div>
          <div className="sm:text-white absolute bottom-6 sm:bottom-0 sm:static w-full font-bold px-7 sm:px-0">
            <span className="sm:hidden text-sm">{newsList[0].date}</span>
            <div className="flex justify-between mt-2 items-baseline">
              <Link
                to={"/news/" + newsList[0].path + "/" + newsList[0].postId}
                className={`sm:text-lg text-[22px] leading-5 sm:${titleColor}`}
              >
                <img
                  src={titleNewsIcon}
                  alt="Title News Icon"
                  className="hidden sm:inline w-4 h-4 mr-2"
                />
                {newsList[0].title}
              </Link>
              <Link
                to={"/news/" + newsList[0].path + "/" + newsList[0].postId}
                className="sm:hidden text-sm underline transition ease-in-out delay-0 hover:scale-110 min-w-fit"
              >
                Xem chi tiết
              </Link>
              <span
                className={classnames(
                  `hidden sm:block sm:text-xs text-sm`,
                  titleColor
                )}
              >
                {newsList[0].date}
              </span>
            </div>
            <span
              className={classnames(
                "hidden sm:block text-sm font-normal mt-[6px] leading-5",
                color
              )}
            >
              {newsList[0].description}
            </span>
          </div>
        </div>
      </div>
      <div className="hidden sm:block w-full h-px my-4 bg-[#E6EAED]"></div>
      <div className="flex-[2] xl:flex-[3] lg:flex-[3] md:flex-[3] flex flex-col justify-between">
        {newsList.map((val, index) => {
          return (
            <React.Fragment key={index}>
              {index !== 0 && index < 4 && (
                <NewsItemRow
                  titleColor={titleColor}
                  val={val}
                  textColorClass={color}
                />
              )}
              {index > 0 && index < 3 && (
                <div className="w-full h-px my-4 bg-[#E6EAED]"></div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  ) : (
    <p className="mt-10 text-[26px] text-white font-semibold text-center">
      Không có bài viết nào
    </p>
  );
};

export default News;
