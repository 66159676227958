import { useEffect } from "react";
import "./style.css";
import { checkShowCookieConsent } from "utils/common";
export const Privacy = () => {
  useEffect(() => {
    checkShowCookieConsent();
  }, []);
  
  useEffect(() => {
    const event = document.getElementById("privacy");
    event?.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  return (
    <div id="privacy">
      <div className="sm:px-4 page-content max-w-[800px] mx-auto">
        <div className="mt-3 truncate pb-0.5 clear-both">
          <h3
            className="float-left font-bold text-lg text-left truncate indent-0"
            style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
          >
            Chính Sách Bảo Mật Thông Tin
          </h3>
        </div>
        <p>
          <strong>Điều 1. GIỚI THIỆU</strong>
        </p>
        <p>
          <strong>1.</strong> Chào mừng Bạn đến với (các) sản phẩm, dịch vụ được
          vận hành bởi Công Ty Cổ Phần VNG và các công ty con, công ty liên kết,
          công ty thành viên của VNG (gọi riêng và gọi chung là, "VNG", "Chúng
          tôi", hay "của Chúng tôi"). VNG nghiêm túc thực hiện trách nhiệm của
          mình liên quan đến bảo mật thông tin theo các quy định về bảo vệ bí
          mật thông tin cá nhân của pháp luật và cam kết tôn trọng quyền riêng
          tư và sự quan tâm của tất cả người dùng đối với các trò chơi điện tử
          trên mạng của VNG ("Trò Chơi") và trang web ("Trang Web"). VNG hiểu rõ
          tầm quan trọng của dữ liệu cá nhân mà Bạn đã tin tưởng giao cho VNG và
          tin rằng VNG có trách nhiệm quản lý, bảo vệ và xử lý dữ liệu cá nhân
          của Bạn một cách thích hợp. Chính sách bảo mật thông tin này ("Chính
          Sách Bảo Mật" hay "Chính Sách") được thiết kế để giúp Bạn hiểu được
          cách thức VNG thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân
          mà Bạn đã cung cấp cho VNG và/hoặc lưu giữ về Bạn, cho dù là hiện nay
          hoặc trong tương lai, cũng như để giúp Bạn đưa ra quyết định sáng suốt
          trước khi cung cấp cho Chúng tôi bất kỳ dữ liệu cá nhân nào của Bạn.
          Chính sách bảo mật này cũng được thiết kế để hỗ trợ Khách hàng của VNG
          hiện đang sống tại Liên Minh Châu Âu (EU) hiểu được các quyền của họ
          đối với các dữ liệu cá nhân được quy định tại Quy định chung về bảo vệ
          dữ liệu của EU khi họ sử dụng Dịch vụ.
        </p>
        <p>
          <strong>2.</strong> "Các Dịch Vụ" hoặc "Dịch Vụ": có nghĩa là các Trò
          Chơi, và/hoặc Trang Web, và/hoặc bất kỳ sản phẩm, dịch vụ nào do VNG
          cung cấp.
        </p>
        <p>
          <strong>3.</strong> "Dữ Liệu Cá Nhân" có nghĩa là dữ liệu, dù đúng hay
          không, về một cá nhân mà thông qua đó có thể được xác định được danh
          tính, hoặc từ dữ liệu đó và thông tin khác mà một tổ chức có hoặc có
          khả năng tiếp cận. Các ví dụ thường gặp về dữ liệu cá nhân có thể gồm
          có tên, số chứng minh nhân dân và thông tin liên hệ.
        </p>
        <p>
          <strong>4.</strong> Bằng việc sử dụng Các Dịch Vụ, đăng ký một tài
          khoản với VNG hoặc truy cập Trang Web, Bạn xác nhận và đồng ý rằng Bạn
          chấp nhận các phương pháp, yêu cầu, và/hoặc chính sách được mô tả
          trong Chính Sách Bảo Mật này, và theo đây Bạn đồng ý cho phép VNG thu
          thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của Bạn như mô tả
          trong Chính Sách Bảo Mật này.
        </p>
        <p>
          <strong>5.</strong> NẾU BẠN KHÔNG ĐỒNG Ý, CHO PHÉP VNG THU THẬP, XỬ LÝ
          DỮ LIỆU CÁ NHÂN CỦA BẠN NHƯ MÔ TẢ TRONG CHÍNH SÁCH NÀY, VUI LÒNG KHÔNG
          SỬ DỤNG CÁC DỊCH VỤ CỦA VNG HAY TRUY CẬP TRÒ CHƠI HOẶC TRANG WEB CỦA
          VNG. Nếu Chúng tôi thay đổi Chính Sách Bảo Mật của mình, Chúng tôi sẽ
          đăng những thay đổi đó hoặc Chính Sách Bảo Mật sửa đổi trên Trang Web
          của Chúng tôi. Chúng tôi có quyền sửa đổi bổ sung Chính Sách Bảo Mật
          này vào bất kỳ thời điểm nào mà không cần sự đồng ý trước của Bạn,
          việc Bạn tiếp tự sử dụng Các Dịch Vụ của VNG được xem là Bạn đã đồng ý
          với Chính Sách Bảo Mật sửa đổi đó.
        </p>
        <p>
          <strong>Điều 2. KHI NÀO VNG SẼ THU THẬP DỮ LIỆU CÁ NHÂN?</strong>
        </p>
        <p>
          <strong>1.</strong> VNG sẽ/có thể thu thập dữ liệu cá nhân về Bạn:
        </p>
        <p>
          <li>
            Khi Bạn đăng ký và/hoặc sử dụng Các Dịch Vụ, Trò Chơi hoặc Trang Web
            của VNG, hoặc mở một tài khoản với Chúng tôi;
          </li>

          <li>
            Khi Bạn gửi bất kỳ biểu mẫu nào, bao gồm, nhưng không giới hạn ở đơn
            đăng ký hoặc các mẫu đơn khác liên quan đến bất kỳ sản phẩm và dịch
            vụ nào của VNG, bằng hình thức trực tuyến hay dưới hình thức khác;
          </li>

          <li>
            Khi Bạn ký kết bất kỳ thỏa thuận nào hoặc cung cấp các tài liệu hoặc
            thông tin khác liên quan đến tương tác giữa Bạn với VNG, hoặc khi
            Bạn sử dụng các sản phẩm và dịch vụ của Chúng tôi;
          </li>
          <li>
            Khi Bạn tương tác với Chúng tôi, chẳng hạn như thông qua các cuộc
            gọi điện thoại (có thể được ghi âm lại), thư từ, fax, gặp gỡ trực
            tiếp, các nền ứng dụng truyền thông xã hội và email;
          </li>
          <li>
            Khi Bạn sử dụng các dịch vụ điện tử của VNG, hoặc tương tác với
            Chúng tôi qua Trò Chơi hoặc Trang Web hoặc Các Dịch Vụ của VNG.
            Trường hợp này bao gồm, nhưng không giới hạn, thông qua tập tin
            cookie mà Chúng tôi có thể triển khai khi Bạn tương tác với các Trò
            Chơi hoặc Trang Web của Chúng tôi;
          </li>
          <li>
            Khi Bạn thực hiện các giao dịch thông qua trang Trò Chơi hoặc Trang
            Web của VNG;
          </li>
          <li>Khi Bạn cung cấp ý kiến phản hồi hoặc gửi khiếu nại cho VNG;</li>
          <li>
            Khi Bạn đăng ký tham gia một cuộc thi, trò chơi hoặc các sự kiện
            theo trò chơi điện tử hoặc tham gia các chương trình khuyến mại;
          </li>
          <li>Trong quá trình Bạn chơi (các) trò chơi điện tử; và</li>
          <li>
            khi Bạn gửi dữ liệu cá nhân của Bạn cho VNG vì bất kỳ lý do gì.
          </li>
        </p>
        <p>
          <strong>2.</strong> Trên đây chỉ là một số trường hợp phổ biến mà VNG
          thu thập dữ liệu cá nhân của Bạn, không phản ánh hết toàn bộ các
          trường hợp mà VNG sẽ thu thập dữ liệu cá nhân của Bạn.
        </p>
        <p>
          <strong>Điều 3. VNG SẼ THU THẬP NHỮNG DỮ LIỆU GÌ?</strong>
        </p>
        <p>
          <strong>1.</strong> Dữ liệu cá nhân mà VNG có thể thu thập bao gồm,
          nhưng không giới hạn:
        </p>
        <p>
          <li>Họ tên;</li>
          <li>Địa chỉ email;</li>
          <li>Ngày tháng năm sinh;</li>
          <li>
            Thông tin của giấy tờ tùy thân (CMND, Căn cước công dân, hộ chiếu,
            và ngày cấp, nơi cấp);
          </li>
          <li>địa chỉ cư trú;</li>
          <li>địa chỉ thanh toán;</li>
          <li>tài khoản ngân hàng và thông tin thanh toán;</li>
          <li>số điện thoại;</li>
          <li>giới tính;</li>
          <li>ảnh chụp gương mặt của Bạn thông qua thiết bị;</li>
          <li>
            thông tin được gửi bởi hoặc liên quan đến (các) thiết bị được sử
            dụng để truy cập vào Các Dịch Vụ của VNG:
          </li>
          <li>
            bất kỳ thông tin nào khác về Bạn (i) khi Bạn đăng nhập để sử dụng
            Các Dịch Vụ của VNG, và (ii) khi Bạn sử dụng Các Dịch Vụ, cũng như
            (iii) thông tin về việc Bạn sử dụng Các Dịch Vụ của VNG như thế nào;
            và
          </li>
          <li>dữ liệu tổng hợp về nội dung người dùng sử dụng.</li>
        </p>
        <p>
          <strong>2.</strong> Bạn đồng ý không cung cấp cho Chúng tôi bất cứ
          thông tin nào không chính xác hoặc gây hiểu nhầm và Bạn đồng ý sẽ
          thông báo cho VNG về bất cứ thông tin nào không chính xác hoặc khi có
          sự thay đổi thông tin. VNG bảo lưu quyền (tùy theo quyết định riêng
          của VNG) được yêu cầu các tài liệu cần thiết khác để xác minh thông
          tin được Bạn cung cấp.
        </p>
        <p>
          <strong>3.</strong> Nếu Bạn sử dụng tài khoản mạng xã hội hoặc tài
          khoản ứng dụng là tài khoản Zalo,và/hoặc số điện thoại của Bạn để đăng
          nhập, sử dụng Các Dịch Vụ của VNG thì Bạn hiểu rõ và đồng ý rằng, VNG
          có quyền truy cập thông tin về Bạn mà Bạn đã cung cấp một cách tự
          nguyện cho nhà cung cấp dịch vụ Tài Khoản Mạng Xã hội, doanh nghiệp
          cung ứng dịch vụ viễn thông của Bạn tuân theo các chính sách của các
          nhà cung cấp dịch vụ này, và VNG sẽ quản lý và sử dụng các dữ liệu cá
          nhân này của Bạn theo các quy định của Chính Sách này tại mọi thời
          điểm.
        </p>
        <p>
          <strong>4.</strong> Nếu Bạn không muốn Chúng tôi thu thập thông tin/dữ
          liệu cá nhân nói trên, Bạn có thể chọn không tham gia vào bất kỳ lúc
          nào bằng cách thông báo bằng văn bản hoặc email đến Nhân Viên Bảo Vệ
          Dữ Liệu của VNG. Có thể tìm thấy thêm thông tin về nội dung trong mục
          "Bạn có thể rút lại sự cho phép, yêu cầu truy cập hoặc điều chỉnh
          thông tin Bạn đã cung cấp cho Chúng tôi bằng cách nào?" dưới đây. Tuy
          nhiên, Bạn cần lưu ý rằng, việc Bạn từ chối hoặc hủy bỏ cho phép Chúng
          tôi thu thập, sử dụng hoặc xử lý dữ liệu cá nhân của Bạn có thể ảnh
          hưởng đến việc Bạn sử dụng Các Dịch Vụ. Ví dụ như dịch vụ xác định vị
          trí sẽ không hoạt động nếu Bạn không cho phép ứng dụng truy cập vị trí
          của Bạn hoặc thậm chí là chấm dứt Các Dịch Vụ giữa Bạn và VNG.
        </p>

        <p>
          <strong>Điều 4. THU THẬP CÁC DỮ LIỆU KHÁC</strong>
        </p>

        <p>
          <strong>1.</strong> Như với hầu hết các trang web và các ứng dụng di
          động khác, thiết bị của Bạn gửi thông tin có thể gồm có: dữ liệu về
          Bạn, được một máy chủ ghi lại khi Bạn sử dụng Các Dịch Vụ của VNG.
          Thông tin này thường bao gồm nhưng không giới hạn địa chỉ IP, hệ điều
          hành của máy tính/thiết bị di động, loại trình duyệt, loại thiết bị di
          động, các đặc điểm của thiết bị di động, mã định danh thiết bị duy
          nhất (UDID) hoặc mã định danh thiết bị di động (MEID) của thiết bị di
          động của Bạn, địa chỉ tham chiếu của Trang Web (nếu có), các trang mà
          Bạn đã truy cập đến trên trang web hoặc ứng dụng di động của VNG và
          thời gian truy cập và đôi khi là "cookie" (có thể vô hiệu hóa bằng
          cách sử dụng tùy chọn trình duyệt của Bạn) để giúp trang web ghi nhớ
          lần truy cập cuối cùng của Bạn. Nếu Bạn đăng nhập, thông tin này được
          liên kết với tài khoản cá nhân của Bạn. Thông tin này cũng được đưa
          vào các số liệu thống kê ẩn danh để giúp Chúng tôi hiểu được khách
          hàng đã truy cập sử dụng Trò Chơi và Trang Web của Chúng tôi như thế
          nào.
        </p>
        <p>
          <strong>2.</strong> Các Dịch Vụ của VNG được cung ứng thông qua các
          ứng dụng di động có thể thu thập thông tin chính xác về địa chỉ của
          thiết bị di động của Bạn sử dụng các công nghệ như GPS, Wi-Fi, ….
          Chúng tôi thu thập, sử dụng, công bố và/hoặc xử lý các thông tin này
          cho một hoặc nhiều mục đích bao gồm, nhưng không giới hạn, các dịch vụ
          được cung cấp dựa trên vị trí mà Bạn yêu cầu hoặc chuyển các nội dung
          có liên quan đến Bạn dựa trên vị trí của Bạn hoặc cho phép Bạn chia sẻ
          vị trí của Bạn cho các Người sử dụng khác như là một phần của các Dịch
          Vụ được cung cấp bởi các ứng dụng di động của Chúng tôi. Đối với phần
          lớn các thiết bị di động, Bạn có thể rút lại sự cho phép để Chúng tôi
          được thu thập các thông tin này dựa trên vị trí của Bạn thông qua các
          cài đặt trên thiết bị. Nếu Bạn có câu hỏi nào về cách thức vô hiệu hóa
          các dịch vụ vị trí trên thiết bị di động của Bạn, vui lòng liên hệ với
          các nhà cung cấp dịch vụ thiết bị di động hoặc nhà sản xuất thiết bị
          của Bạn.
        </p>
        <p>
          <strong>3.</strong> Các Dịch Vụ của VNG có thể sử dụng các công nghệ
          như ARKit (Agumented Reality hay gọi tắt là AR – Công nghệ thực tế
          ảo), Camera API, TrueDepth API … hoặc các công nghệ tương tự được cung
          cấp bởi hệ điều hành của thiết bị di động; các công nghệ này được sử
          dụng để ghi nhận thông tin biểu hiện trên khuôn mặt của người dùng và
          dùng cho tính năng trong ứng dụng. Đối với các thông tin này, Chúng
          tôi không sử dụng cho bất kỳ mục đích nào khác ngoài các tính năng
          được cung cấp trong ứng dụng; Bạn có quyền từ chối cấp quyền truy cập
          camera cho các tính năng này bất kỳ lúc nào (nhưng Bạn cần lưu ý rằng,
          khi Bạn ngưng cấp quyền truy cập camera cho ứng dụng thì một số tính
          năng của ứng dụng sẽ không thể hoạt động được); và Chúng tôi không lưu
          trữ cũng như không chia sẻ các thông tin này cho bất kỳ bên thứ ba
          nào.
        </p>

        <p>
          <strong>Điều 5. COOKIES</strong>
        </p>
        <p>
          <strong>1.</strong> Đôi khi Chúng tôi có thể sử dụng "cookie" hoặc các
          tính năng khác để cho phép Chúng tôi hoặc các bên thứ ba thu thập hoặc
          chia sẻ thông tin sẽ giúp Chúng tôi cải thiện Trang Web, Trò Chơi của
          mình và Các Dịch Vụ mà Chúng tôi cung cấp, hoặc giúp Chúng tôi đưa ra
          các dịch vụ và tính năng mới. "Cookie" là các mã định danh Chúng tôi
          gửi đến máy tính hoặc thiết bị di động của Bạn, cho phép Chúng tôi
          nhận dạng máy tính hoặc thiết bị của Bạn và cho Chúng tôi biết khi nào
          Các Dịch Vụ hoặc Trang Web được sử dụng hay truy cập, bởi bao nhiêu
          người và để theo dõi những hoạt động trong Trang Web hoặc Các Trò Chơi
          của Chúng tôi. Chúng tôi có thể liên kết thông tin cookie với Dữ Liệu
          Cá Nhân. Cookie cũng liên kết với thông tin về những nội dung Bạn đã
          chọn đối với các trang mua sắm bạn đã xem, hoặc các trò chơi điện tử
          mà Bạn đã chơi. Thông tin này được sử dụng để ví dụ như theo dõi giỏ
          hàng. Cookie cũng được sử dụng để cung cấp nội dung dựa trên quan tâm
          của Bạn và để theo dõi việc sử dụng của các Dịch Vụ.
        </p>
        <p>
          <strong>2.</strong> Bạn có thể từ chối sử dụng cookie bằng cách chọn
          các thiết lập thích hợp trên trình duyệt của Bạn. Tuy nhiên, vui lòng
          lưu ý rằng nếu Bạn thực hiện thao tác này Bạn có thể không sử dụng
          được các chức năng đầy đủ của Trang Web, Trò Chơi hoặc Các Dịch Vụ của
          Chúng tôi.
        </p>

        <p>
          <strong>
            Điều 6. VNG SỬ DỤNG THÔNG TIN BẠN CUNG CẤP CHO VNG NHƯ THẾ NÀO?
          </strong>
        </p>
        <p>
          <strong>1.</strong> VNG có thể thu thập, sử dụng, tiết lộ và/hoặc xử
          lý dữ liệu cá nhân của Bạn vì một hoặc nhiều mục đích sau đây:
        </p>
        <p>
          <li>
            Để xem xét và/hoặc xử lý đơn đăng ký/giao dịch của Bạn với Chúng tôi
            hoặc giao dịch hay thư từ của Bạn với các bên thứ ba qua Các Dịch
            Vụ;
          </li>
          <li>
            Để quản lý, điều hành, cung cấp và/hoặc quản lý việc Bạn sử dụng
            và/hoặc truy cập Các Dịch Vụ, Trang Web và các Trò Chơi của Chúng
            tôi (bao gồm nhưng không giới hạn), các sở thích của Bạn, cũng như
            quan hệ và tài khoản người dùng của Bạn với Chúng tôi;
          </li>
          <li>
            Để đáp ứng, xử lý, giải quyết hoặc hoàn tất một giao dịch và/hoặc
            đáp ứng các yêu cầu của Bạn đối với các sản phẩm và dịch vụ nhất
            định và thông báo cho Bạn về các vấn đề dịch vụ và các hoạt động tài
            khoản bất thường;
          </li>
          <li>
            Để thực thi các Điều Khoản Dịch Vụ của Chúng tôi hoặc bất kỳ thỏa
            thuận giấy phép người dùng cuối, điều khoản sử dụng nào áp dụng;
          </li>
          <li>
            Để bảo vệ sự an toàn cá nhân và các quyền, tài sản hoặc sự an toàn
            của người khác;
          </li>
          <li>Để nhận dạng và/hoặc xác minh;</li>
          <li>
            Để duy trì và quản lý bất kỳ bản cập nhật phần mềm nào và/hoặc các
            bản cập nhật khác và sự hỗ trợ có thể được yêu cầu tùy lúc nhằm đảm
            bảo Các Dịch Vụ của Chúng tôi hoạt động suôn sẻ;
          </li>
          <li>
            Để giải quyết hoặc tạo điều kiện dịch vụ khách hàng, thực hiện các
            chỉ thị của Bạn, giải quyết hoặc trả lời bất kỳ thắc mắc nào được
            gửi bởi (hoặc nhằm được gửi bởi) Bạn hoặc thay mặt Bạn;
          </li>
          <li>
            Để liên hệ với Bạn hoặc liên lạc với Bạn qua điện thoại, tin nhắn
            văn bản và/hoặc tin nhắn fax, email và/hoặc thư hoặc cách khác nhằm
            mục đích quản trị và/hoặc quản lý quan hệ của Bạn với Chúng tôi hoặc
            việc Bạn sử dụng Các Dịch Vụ của Chúng tôi, chẳng hạn như nhưng
            không giới hạn ở việc truyền đạt thông tin hành chính cho Bạn liên
            quan đến Các Dịch Vụ của Chúng tôi. Bạn xác nhận và đồng ý rằng sự
            liên lạc như thế của Chúng tôi có thể là theo cách gửi thư qua đường
            bưu điện, tài liệu hoặc thông báo cho Bạn, có thể gồm có tiết lộ dữ
            liệu cá nhân nhất định về Bạn để cung cấp các tài liệu đó cũng như
            trên bao bì/phong bì;
          </li>
          <li>
            Để thông báo cho Bạn khi một người dùng khác đã gửi cho Bạn một tin
            nhắn riêng tư hoặc đăng nhận xét cho Bạn trong các Trò chơi hoặc
            trên Trang Web;
          </li>
          <li>
            Để tiến hành các hoạt động nghiên cứu, phân tích và phát triển (bao
            gồm, nhưng không giới hạn ở, phân tích dữ liệu, khảo sát, phát triển
            và/hoặc lập đặc tính sản phẩm và dịch vụ), để phân tích cách thức
            Bạn sử dụng Các Dịch Vụ của Chúng tôi, để cải thiện Các Dịch Vụ hoặc
            sản phẩm của Chúng tôi và/hoặc để cải thiện trải nghiệm khách hàng
            của Bạn;
          </li>
          <li>
            Để cho phép quảng cáo và các đợt kiểm tra và khảo sát khác, ngoài
            những hoạt động khác, xác thực quy mô và thành phần của đối tượng
            chơi trò chơi điện tử, và hiểu được trải nghiệm của họ với Các Dịch
            Vụ của VNG;
          </li>
          <li>
            Vì mục đích tiếp thị, trong trường hợp này, để gửi cho Bạn qua các
            phương thức liên lạc khác nhau, thông tin và tài liệu tiếp thị và
            quảng bá liên quan đến các sản phẩm và/hoặc dịch vụ (bao gồm, nhưng
            không giới hạn ở, các sản phẩm và/hoặc dịch vụ của các bên thứ ba mà
            VNG có thể hợp tác hoặc liên kết) có thể bán, tiếp thị hoặc quảng
            bá, cho dù các sản phẩm hoặc dịch vụ đó tồn tại vào lúc này hoặc
            được tạo ra trong tương lai. Bạn có thể hủy đăng ký nhận các thông
            tin tiếp thị tại bất cứ thời điểm nào bằng cách sử dụng chức năng
            hủy đăng ký trong các tài liệu tiếp thị điện tử. Chúng tôi có thể sử
            dụng các thông tin liên hệ của Bạn để gửi các bản tin từ Chúng tôi
            hoặc từ các công ty con, công ty liên kết, công ty thành viên của
            VNG;
          </li>
          <li>
            Để đáp ứng các thủ tục pháp lý hoặc để tuân thủ hoặc theo quy định
            của pháp luật hiện hành nào và các yêu cầu của cơ quan nhà nước có
            thẩm quyền;
          </li>
          <li>
            Để lập số liệu thống kê và nghiên cứu đáp ứng yêu cầu báo cáo
            và/hoặc duy trì sổ sách nội bộ hoặc theo quy chế;
          </li>
          <li>
            Để thực hiện quy trình tìm hiểu và xác minh hoặc các hoạt động sàng
            lọc khác (bao gồm, nhưng không giới hạn ở, kiểm tra lý lịch) tuân
            thủ các nghĩa vụ theo quy định pháp luật hoặc quản lý hoặc các thủ
            tục kiểm soát rủi ro của Chúng tôi, có thể được pháp luật yêu cầu
            hoặc có thể đã được Chúng tôi áp dụng;
          </li>
          <li>Để kiểm tra Các Dịch Vụ của Chúng tôi hoặc hoạt động của VNG;</li>
          <li>
            Để ngăn chặn hoặc điều tra bất kỳ hành vi vi phạm thực tế hoặc nghi
            ngờ Điều Khoản Dịch Vụ của Chúng tôi, hoạt động gian lận, phi pháp,
            thiếu sót hay hành vi sai trái nào, cho dù có liên quan đến việc Bạn
            sử dụng Các Dịch Vụ của Chúng tôi hay không hay bất kỳ vấn đề nào
            phát sinh từ quan hệ của Bạn với Chúng tôi;
          </li>
          <li>
            Để lưu trữ, lập máy chủ, sao lưu (cho dù là vì mục đích khôi phục
            sau thảm họa hoặc mục đích khác) đối với dữ liệu cá nhân của Bạn;
          </li>
          <li>
            Để xử lý và/hoặc tạo thuận tiện cho một giao dịch tài sản kinh doanh
            hoặc một giao dịch tài sản kinh doanh tiềm năng, trường hợp giao
            dịch đó liên quan đến VNG như một bên tham gia hoặc chỉ liên quan
            đến một công ty con hay công ty liên kết của VNG như một bên tham
            gia hoặc liên quan đến VNG và/hoặc bất kỳ một hay nhiều công ty con
            hoặc công ty liên kết của VNG như (các) bên tham gia, và có thể có
            các tổ chức bên thứ ba khác tham gia giao dịch như thế. "Giao dịch
            tài sản kinh doanh" là các giao dịch mua, bán, cho thuê, sáp nhập,
            hợp nhất hoặc bất kỳ hoạt động mua lại, thanh lý hay tài trợ nào của
            một tổ chức hoặc một phần của một tổ chức hoặc của bất kỳ hoạt động
            kinh doanh hay tài sản nào của một tổ chức; và/hoặc
          </li>
          <li>
            Bất kỳ mục đích nào mà Chúng tôi thông báo cho Bạn tại thời điểm xin
            sự cho phép của Bạn. (gọi chung là "Các Mục Đích").
          </li>
        </p>
        <p>
          <strong>2.</strong> Vì Các Mục Đích mà Chúng tôi sẽ/có thể thu thập,
          sử dụng, tiết lộ hoặc xử lý dữ liệu cá nhân của Bạn phụ thuộc vào hoàn
          cảnh hiện có, mục đích đó có thể không xuất hiện bên trên. Tuy nhiên,
          Chúng tôi sẽ thông báo cho Bạn biết mục đích khác đó tại thời điểm xin
          sự cho phép của Bạn, trừ phi việc xử lý dữ liệu áp dụng mà không có sự
          đồng ý của Bạn là được phép theo các quy định của pháp luật về bảo vệ
          bí mật thông tin cá nhân hoặc theo quy định pháp luật.
        </p>

        <p>
          <strong>
            Điều 7. VNG BẢO VỆ VÀ LƯU TRỮ THÔNG TIN KHÁCH HÀNG BẰNG CÁCH NÀO
          </strong>
        </p>
        <p>
          <strong>1.</strong> VNG thực hiện các biện pháp bảo mật khác nhau và
          luôn nỗ lực để đảm bảo sự an toàn của dữ liệu cá nhân của Bạn trên các
          hệ thống của VNG. Dữ liệu cá nhân của người dùng được lưu trữ đằng sau
          các mạng bảo mật và chỉ có thể được truy cập bởi một số nhân viên có
          quyền truy cập đặc biệt đến các hệ thống của VNG. Tuy nhiên, chắc chắn
          không thể có sự bảo đảm an toàn tuyệt đối.
        </p>
        <p>
          <strong>2.</strong> VNG sẽ duy trì dữ liệu cá nhân tuân theo các quy
          định của pháp luật về bảo vệ bí mật thông tin cá nhân và/hoặc các quy
          định pháp luật hiện hành khác. Có nghĩa là, VNG sẽ hủy hoặc xóa thông
          tin nhận dạng ra khỏi dữ liệu cá nhân của Bạn khi VNG có lý do hợp lý
          để xác định rằng (i) việc lưu giữ dữ liệu cá nhân đó không còn phục vụ
          mục đích thu thập dữ liệu cá nhân đó nữa; (ii) việc lưu giữ không còn
          cần thiết cho bất kỳ mục đích hợp pháp hay mục đích kinh doanh nào và
          (iii) không còn các lợi ích hợp pháp nào khác để tiếp tục lưu giữ các
          dữ liệu cá nhân này. Nếu Bạn ngưng sử dụng Các Dịch Vụ của VNG, hoặc
          quyền của Bạn được sử dụng Trang Web, Trò chơi và/hoặc Các Dịch Vụ bị
          chấm dứt hoặc được rút lại, Chúng tôi có thể tiếp tục lưu, sử dụng
          và/hoặc tiết lộ dữ liệu cá nhân của Bạn tuân theo Chính Sách Bảo Mật
          này và các nghĩa vụ của Chúng tôi theo các quy định của pháp luật về
          bảo vệ bí mật thông tin cá nhân. Tùy theo luật áp dụng, Chúng tôi có
          thể tiêu hủy dữ liệu cá nhân của Bạn một cách an toàn mà không cần
          thông báo trước cho Bạn.
        </p>

        <p>
          <strong>
            Điều 8. VNG CÓ TIẾT LỘ THÔNG TIN THU THẬP TỪ NGƯỜI TRUY CẬP HAY
            KHÔNG?
          </strong>
        </p>
        <p>
          <strong>1.</strong> Trong quá trình thực hiện hoạt động kinh doanh,
          Chúng tôi sẽ/có thể cần phải tiết lộ dữ liệu cá nhân của Bạn cho các
          nhà phát triển Trò Chơi, nhà cung cấp dịch vụ, bên thứ ba, đại lý
          và/hoặc các công ty con và/hoặc các công ty liên kết và/hoặc công ty
          thành viên của VNG, và/hoặc các bên thứ ba khác, vì một hay nhiều Các
          Mục Đích nói trên. Các nhà cung cấp dịch vụ bên thứ ba, đại lý và/hoặc
          các công ty con và/hoặc các công ty liên kết hoặc công ty thành viên
          của VNG và/hoặc các bên thứ ba khác như thế sẽ xử lý dữ liệu cá nhân
          của Bạn hoặc thay mặt Chúng tôi vì một hoặc nhiều Các Mục Đích nói
          trên. Các bên thứ ba như thế bao gồm, nhưng không giới hạn ở:
        </p>
        <p>
          <li>công ty con, công ty liên kết và công ty thành viên của VNG;</li>
          <li>
            nhà thầu, đại lý, nhà cung cấp dịch vụ và các bên thứ ba khác mà
            Chúng tôi thuê để hỗ trợ hoạt động kinh doanh của Chúng tôi. Những
            bên này bao gồm, nhưng không giới hạn ở những bên cung cấp các dịch
            vụ quản trị hoặc các dịch vụ khác cho Chúng tôi chẳng hạn như công
            ty bưu chính, công ty viễn thông, công ty công nghệ thông tin và
            trung tâm dữ liệu;
          </li>
          <li>
            người mua hoặc người thừa nhiệm khác trong trường hợp sáp nhập,
            thoái vốn, tái cơ cấu, tái tổ chức, giải thể hoặc bán hay chuyển
            nhượng một phần hoặc tất cả tài sản của VNG, cho dù là vấn đề đang
            diễn ra hay đang trong thủ tục phá sản, thanh lý hoặc thủ tục tương
            tự, trong đó dữ liệu cá nhân VNG lưu giữ về người dùng của Chúng tôi
            nằm trong các tài sản được chuyển nhượng; hoặc cho một bên đối tác
            trong một giao dịch tài sản kinh doanh mà VNG hoặc bất kỳ công ty
            con, công ty liên kết hay công ty thành viên của VNG có tham gia
            giao dịch; và
          </li>
          <li>
            bên thứ ba mà Chúng tôi tiết lộ thông tin vì một trong các Mục Đích
            và các bên thứ ba đó ngược lại họ sẽ thu thập và xử lý dữ liệu cá
            nhân của Bạn vì một hoặc nhiều Mục Đích
          </li>
        </p>
        <p>
          <strong>2.</strong> Trường hợp này có thể yêu cầu, ngoài các yêu cầu
          khác, tiết lộ lịch sử chơi Trò Chơi của Bạn và/hoặc tiết lộ các hồ sơ
          cho thấy các địa chỉ IP nào đã xem các Trò Chơi nào. Chúng tôi cũng sẽ
          chia sẻ thông tin thống kê và thông tin nhân khẩu về người dùng của
          Chúng tôi và việc họ sử dụng Các Dịch Vụ với các nhà cung cấp dịch vụ
          quảng cáo và lập trình. Trường hợp này sẽ không bao gồm bất kỳ thông
          tin nào có thể được sử dụng để xác định danh tính cụ thể của Bạn hoặc
          tiết lộ thông tin cá nhân về Bạn.
        </p>
        <p>
          <strong>3.</strong> Để tránh nghi ngờ, trong trường hợp các quy định
          của pháp luật về bảo vệ bí mật thông tin cá nhân hoặc các điều luật
          hiện hành khác cho phép một tổ chức chẳng hạn như Chúng tôi thu thập,
          sử dụng hoặc tiết lộ dữ liệu cá nhân của Bạn mà không cần sự đồng ý
          của Bạn, sự cho phép như thế của pháp luật sẽ tiếp tục áp dụng.
        </p>
        <p>
          <strong>4.</strong> Các bên thứ ba có thể chặn hoặc truy cập trái phép
          dữ liệu cá nhân được gửi đến hoặc có trên trang web, các phần mềm, ứng
          dụng, công nghệ được áp dụng có thể hoạt động không chính xác hoặc
          không hoạt động như dự kiến, hoặc có người có thể truy cập, lạm dụng
          hoặc sử dụng sai trái thông tin mà không phải lỗi của Chúng tôi. Tuy
          nhiên Chúng tôi sẽ triển khai các biện pháp bảo mật hợp lý để bảo vệ
          dữ liệu cá nhân của Bạn theo quy định của các quy định của pháp luật
          về bảo vệ bí mật thông tin cá nhân; tuy nhiên không thể đảm bảo sự bảo
          mật tuyệt đối chẳng hạn như nhưng không giới hạn ở khi trường hợp tiết
          lộ trái phép phát sinh từ hoạt động tin tặc vì ý đồ xấu hoặc phức tạp
          của các nội dung có độc không phải lỗi của Chúng tôi.
        </p>
        <p>
          <strong>
            Điều 9. THÔNG TIN VỀ TRẺ EM (TRỪ TRẺ EM ĐANG SINH SỐNG TẠI EU)
          </strong>
        </p>

        <p>
          Các Dịch Vụ của Chúng tôi rất đa dạng và cung ứng cho nhiều đối tượng
          với nhiều độ tuổi khác nhau. Đối với trẻ em dưới 14 tuổi, việc tạo tài
          khoản, sử dụng Các Dịch Vụ của Chúng tôi cần được sự cho phép và giám
          sát của cha/mẹ hoặc người giám hộ của trẻ. Cha/mẹ hoặc người giám hộ
          của trẻ em dưới 14 tuổi vui lòng giám sát và đảm bảo trẻ dưới 14 tuổi
          mà mình đang giám hộ không tự đăng ký tài khoản để sử dụng Các Dịch Vụ
          và/hoặc cung cấp các thông tin cá nhân của trẻ cho Chúng tôi mà không
          có sự giám sát, đồng ý của cha/mẹ hoặc người giám hộ của trẻ. Trong
          trường hợp thông tin cá nhân của của trẻ em dưới 14 tuổi do cha/mẹ
          hoặc giám hộ được cung cấp cho Chúng tôi, hoặc cha/mẹ, người giám hộ
          đồng ý cung cấp thông tin của trẻ cho Chúng tôi thì cha/mẹ hoặc người
          giám hộ theo đây đồng ý với việc xử lý, sử dụng thông tin của trẻ em
          dưới 14 tuổi có liên quan, và đồng ý chịu sự điều chỉnh của Chính Sách
          này thay mặt cho người được giám hộ. Chúng tôi sẽ khóa bất kỳ tài
          khoản nào chỉ được sử dụng bởi đối tượng trẻ em như vậy và sẽ gỡ
          và/hoặc xóa bất kỳ dữ liệu cá nhân nào mà Chúng tôi cho là đã được gửi
          bởi bất kỳ trẻ em nào dưới 14 tuổi.
        </p>
        <p>
          <strong>Điều 10. THÔNG TIN THU THẬP BỞI CÁC BÊN THỨ BA</strong>
        </p>

        <p>
          <strong>1.</strong> Trang Web và Trò Chơi của Chúng tôi có thể sử dụng
          Google Analytics, một dịch vụ phân tích web được cung cấp bởi Google,
          Inc. ("Google"). Google Analytics sử dụng cookie, là các tập tin văn
          bản được đặt vào thiết bị của Bạn, để giúp trang web phân tích cách
          thức người dùng sử dụng Các Dịch Vụ của Chúng tôi. Thông tin được tạo
          bởi cookie về việc Bạn sử dụng trang web (bao gồm địa chỉ IP của Bạn)
          sẽ được gửi đến và lưu bởi Google trên các máy chủ tại Hoa Kỳ. Google
          sẽ sử dụng thông tin này để đánh giá việc Bạn sử dụng Các Dịch Vụ của
          Chúng tôi, soạn báo cáo về hoạt động trang web dành cho các nhà điều
          hành trang web và cung cấp các dịch vụ khác liên quan đến hoạt động
          trang web và việc sử dụng Internet. Google cũng có thể gửi thông tin
          này cho các bên thứ ba trong trường hợp luật pháp có quy định như thế,
          hoặc trường hợp các bên thứ ba đó xử lý thông tin thay mặt Google.
          Google sẽ không liên kết địa chỉ IP của Bạn với bất kỳ dữ liệu nào
          khác mà Google nắm giữ.
        </p>
        <p>
          <strong>2.</strong> Trang Web và Trò Chơi của Chúng tôi có thể sử dụng
          AppsFlyer, một nền tảng ứng dụng tiếp thị được cung cấp bởi AppsFlyer
          Ltd. ("AppsFlyer"). AppsFlyer sử dụng bộ SDK (Software Development
          Kit) được đặt vào thiết bị của Bạn để giúp Chúng tôi theo dõi và tối
          ưu hóa việc cung ứng Các Dịch Vụ đến cho Bạn. Thông tin được tạo bởi
          SDK về việc Bạn sử dụng ứng dụng của Chúng tôi (bao gồm địa chỉ IP của
          Bạn) sẽ được gửi đến và lưu bởi AppsFlyer trên các máy chủ đặt trên
          toàn cầu. AppsFlyer sẽ sử dụng thông tin này để đánh giá việc Bạn sử
          dụng Các Dịch Vụ của Chúng tôi, soạn báo cáo về hoạt động của ứng dụng
          dành cho các nhà phát triển, vận hành ứng dụng và cung cấp các dịch vụ
          khác liên quan đến hoạt động của ứng dụng và việc sử dụng Internet.
          AppsFlyer cũng có thể gửi thông tin này cho các bên thứ ba trong
          trường hợp luật pháp có quy định như thế, hoặc trường hợp các bên thứ
          ba đó xử lý thông tin thay mặt AppsFlyer. AppsFlyer sẽ không liên kết
          địa chỉ IP của Bạn với bất kỳ dữ liệu nào khác mà AppsFlyer nắm giữ.
        </p>
        <p>
          <strong>3.</strong> Các Dịch Vụ của Chúng tôi cũng có thể sử dụng
          TrueID, một ứng dụng nhận diện gương mặt và Giấy tờ tùy thân (như
          chứng minh nhân dân, căn cước công dân, hộ chiếu) được cung cấp bởi
          Công Ty TNHH Phát Triển Phần Mềm VNG – một công ty thành viên của VNG
          ("TrueID") để tăng cường việc nhận diện nhằm bảo mật tài khoản của
          Bạn. TrueID sẽ sử dụng camera trên thiết bị của Bạn để ghi nhận, chụp
          lại gương mặt và Giấy tờ tùy thân của Bạn, sử dụng thuật toán và AI
          (trí tuệ nhân tạo) để xử lý nhằm phát hiện các điểm bất thường (như
          gian lận, gương mặt và ảnh trên Giấy tờ tùy thân không chính xác …).
          TrueID sẽ hỗ trợ Chúng tôi phát hiện các điểm bất thường, đưa ra các
          khuyến cáo, gợi ý để xác thực tài khoản của Bạn. TrueID có thể gửi các
          thông này cho bên thứ ba để lưu trữ, xử lý trong trường hợp luật pháp
          có quy định như thế hoặc trường hợp bên thứ ba đó xử lý thông tin thay
          mặt TrueID. TrueID cũng có nghĩa vụ bảo mật các thông tin của Bạn mà
          TrueID lưu trữ, xử lý tương tự như nghĩa vụ bảo mật của VNG.
        </p>
        <p>
          <strong>4.</strong> Chúng tôi, và các bên thứ ba, có thể thỉnh thoảng
          cung cấp các bản tải về ứng dụng phần mềm cho Bạn sử dụng trên hoặc
          thông qua Các Dịch Vụ. Những ứng dụng này có thể truy cập riêng, và
          cho phép một bên thứ ba xem, thông tin nhận dạng của Bạn, chẳng hạn
          như tên, ID người dùng của Bạn, địa chỉ IP của thiết bị của Bạn hoặc
          thông tin khác chẳng hạn như Trò Chơi mà Bạn đang chơi trong bất kỳ
          phiên truy cập cụ thể nào, và bất kỳ cookie, bộ SDK nào trước đây Bạn
          có thể đã cài đặt hoặc đã được cài đặt cho Bạn bởi một ứng dụng phần
          mềm hoặc trang web của bên thứ ba. Ngoài ra, các ứng dụng này có thể
          yêu cầu Bạn cung cấp thêm thông tin trực tiếp cho các bên thứ ba. Các
          sản phẩm hoặc dịch vụ của bên thứ ba được cung cấp thông qua các ứng
          dụng này không thuộc sở hữu hay quyền kiểm soát của VNG. Bạn nên đọc
          các điều khoản và các chính sách khác được công bố bởi các bên thứ ba
          đó trên trang web của họ hoặc nơi khác.
        </p>
        <p>
          <strong>
            Điều 11. LOẠI TRỪ TRÁCH NHIỆM VỀ NGHĨA VỤ BẢO MẬT VÀ CÁC TRANG WEB
            BÊN THỨ BA
          </strong>
        </p>
        <p>
          <strong>1.</strong> VNG KHÔNG ĐẢM BẢO TÍNH BẢO MẬT ĐỐI VỚI DỮ LIỆU CÁ
          NHÂN VÀ/HOẶC THÔNG TIN KHÁC MÀ BẠN CUNG CẤP TRÊN CÁC TRANG WEB, ỨNG
          DỤNG CỦA BÊN THỨ BA. Chúng tôi thực hiện các biện pháp bảo mật khác
          nhau để duy trì sự an toàn của Dữ Liệu Cá Nhân của Bạn mà Chúng tôi
          lưu giữ hoặc kiểm soát. Dữ Liệu Cá Nhân của Bạn được lưu đằng sau các
          mạng bảo mật và chỉ có thể được truy cập bởi một số cá nhân giới hạn
          có quyền truy cập đặc biệt đến các hệ thống của Chúng tôi, và đã được
          yêu cầu bảo mật dữ liệu cá nhân đó. Khi Bạn đặt hàng hoặc truy cập dữ
          liệu cá nhân của Bạn, Chúng tôi đề nghị sử dụng một thiết bị bảo mật.
          Tất cả dữ liệu cá nhân hoặc thông tin cá nhân Bạn cung cấp sẽ được mã
          hóa vào các cơ sở dữ liệu của Chúng tôi để chỉ được truy cập như mô tả
          bên trên.
        </p>

        <p>
          <strong>2.</strong> Nhằm cung cấp cho Bạn giá trị gia tăng, Chúng tôi
          có thể chọn các trang web bên thứ ba khác nhau để liên kết, và đóng
          khung bên trong Trang Web hoặc Trò Chơi. Chúng tôi cũng có thể tham
          gia các quan hệ cùng tiếp thị và các quan hệ khác để cung cấp dịch vụ
          thương mại điện tử, quảng cáo và các dịch vụ và tính năng khác cho
          khách truy cập. Những trang được liên kết này có các chính sách về
          quyền riêng tư cũng như các biện pháp bảo mật riêng và độc lập. Ngay
          cả khi bên thứ ba đó có liên kết với Chúng tôi, Chúng tôi cũng không
          kiểm soát các trang web được liên kết này, mỗi trang đó có các phương
          pháp bảo vệ quyền riêng tư và thu thập dữ liệu riêng biệt, độc lập với
          Chúng tôi. Dữ liệu thu thập bởi các đối tác cùng tiếp thị của Chúng
          tôi hoặc các trang web bên thứ ba (ngay cả khi được cung cấp trên hoặc
          thông qua Trang Web hoặc Trò Chơi của Chúng tôi) có thể không được
          nhận bởi Chúng tôi.
        </p>
        <p>
          <strong>3.</strong> Do đó Chúng tôi không chịu trách nhiệm hay trách
          nhiệm pháp lý đối với nội dung, các biện pháp bảo mật (hoặc sự thiếu
          biện pháp bảo mật) và các hoạt động của các trang web được liên kết
          này. Những trang web được liên kết này chỉ vì sự thuận tiện cho Bạn và
          do đó Bạn tự chịu trách nhiệm khi truy cập chúng. Tuy nhiên, Chúng tôi
          tìm cách bảo vệ tính toàn vẹn của Trang Web và Trò Chơi của Chúng tôi
          và các liên kết được đặt trên từng trang web đó và do đó Chúng tôi
          hoan nghênh ý kiến phản hồi về các trang web được liên kết này (bao
          gồm, nhưng không giới hạn ở, nếu một trang web cụ thể không hoạt
          động).
        </p>
        <p>
          <strong>
            Điều 12. BẠN CÓ THỂ RÚT TÊN, XÓA TÊN, YÊU CẦU TRUY CẬP HOẶC ĐIỀU
            CHỈNH THÔNG TIN BẠN ĐÃ CUNG CẤP CHO CHÚNG TÔI BẰNG CÁCH NÀO?
          </strong>
        </p>
        <p>
          <strong>1.</strong> Rút Lại Sự Đồng Ý
        </p>
        <p>
          <li>
            Bạn có thể rút lại sự đồng ý cho phép thu thập, sử dụng và/hoặc tiết
            lộ dữ liệu cá nhân của Bạn mà Chúng tôi đang lưu giữ hoặc kiểm soát
            bằng cách gửi văn bản hoặc gửi tới trang{" "}
            <a href="https://zagoo.vn/support">
              <strong>https://zagoo.vn/support</strong>
            </a>
            , hoặc gửi email đến hotro@zagoo.vn và Chúng tôi sẽ xử lý yêu cầu
            theo các quy định Chính Sách Bảo Mật này và các nghĩa vụ của Chúng
            tôi được quy định bởi pháp luật.
          </li>
          <li>
            Tuy nhiên, việc Bạn rút lại sự đồng ý có thể có nghĩa là Chúng tôi
            sẽ không thể tiếp tục cung cấp Các Dịch Vụ cho Bạn, và Chúng tôi có
            thể cần phải chấm dứt quan hệ hiện tại của Bạn và/hoặc hợp đồng của
            Bạn với Chúng tôi và không phải bồi thường cho bất kỳ tổn thất nào
            của Bạn.
          </li>
        </p>
        <p>
          <strong>2.</strong> Yêu Cầu Truy Cập đến hoặc Sửa Dữ Liệu Cá Nhân
        </p>
        <p>
          <li>
            Nếu Bạn đã đăng ký một tài khoản với Chúng tôi, cá nhân Bạn có thể
            truy cập và/hoặc sửa dữ liệu cá nhân của Bạn mà Chúng tôi đang lưu
            giữ hoặc kiểm soát thông qua trang Thiết Lập Tài Khoản hoặc trên
            Trang Web hoặc Trò Chơi. Nếu Bạn chưa đăng ký tài khoản với Chúng
            tôi, cá nhân Bạn có thể yêu cầu truy cập và/hoặc sửa dữ liệu cá nhân
            của Bạn mà Chúng tôi đang lưu giữ hoặc kiểm soát bằng cách gửi yêu
            cầu bằng văn bản cho Chúng tôi. Chúng tôi sẽ cần có đủ thông tin từ
            Bạn để xác định danh tính của Bạn cũng như bản chất yêu cầu của Bạn
            để có thể giải quyết yêu cầu của Bạn. Do đó, vui lòng gửi yêu cầu
            bằng văn bản của Bạn bằng cách gửi yêu cầu tại { }
            <a href="https://zagoo.vn/support"> 
              <strong>https://zagoo.vn/support</strong>
            </a> { }
            hoặc email { }
            <a href="mailto: hotro@zagoo.vn">
              <strong>hotro@zagoo.vn</strong>
            </a>
          </li>
          <li>
            Chúng tôi có thể tính một khoản phí hợp lý cho Bạn để giải quyết và
            xử lý yêu cầu truy cập dữ liệu cá nhân của Bạn. Nếu Chúng tôi có
            tính phí, Chúng tôi sẽ cung cấp cho Bạn ước tính lệ phí bằng văn bản
            hoặc email. Vui lòng lưu ý rằng Chúng tôi không buộc phải đáp ứng
            hay giải quyết yêu cầu truy cập của Bạn trừ phi Bạn đã đồng ý đóng
            phí.
          </li>
          <li>
            Chúng tôi bảo lưu quyền từ chối sửa dữ liệu cá nhân của Bạn theo các
            quy định của pháp luật về bảo vệ bí mật thông tin cá nhân, trường
            hợp các điều luật đó yêu cầu và/hoặc cho phép một tổ chức từ chối
            sửa dữ liệu cá nhân trong các trường hợp như thế.
          </li>
        </p>
        <p>
          <strong>
            Điều 13. QUY ĐỊNH BỔ SUNG CHO NGƯỜI DÙNG TẠI CÁC QUỐC GIA LIÊN MINH
            CHÂU ÂU (EU)
          </strong>
        </p>
        <p>
          Quy định tại Điều này chỉ áp dụng nếu Bạn đang sinh sống tại Liên Minh
          Châu Âu ("EU"). Các điều khoản này được ưu tiên áp dụng hơn trong
          trường hợp có sự khác biệt trong các điều khoản khác của Chính Sách
          Bảo Mật này.
        </p>
        <p>
          <strong>1.</strong> Tính Linh Động
        </p>
        <p>
          <li>
            Bạn có quyền nhận từ Chúng tôi các Thông Tin Cá Nhân mà Bạn đã cung
            cấp trước đó cho Chúng tôi và các thông tin đã được xử lý dựa trên
            sự cho phép của Bạn theo định dạng được cấu trúc, có khả năng sử
            dụng thông thường và có thể đọc máy và quyền yêu cầu Chúng tôi
            chuyển các Dữ Liệu Cá Nhân này cho Bên khác.
          </li>
          <li>
            Nếu Bạn muốn Chúng tôi chuyển giao Dữ Liệu Cá Nhân cho Bên thứ ba,
            Bạn vui lòng đảm bảo các chi tiết của Bên thứ ba này và lưu ý rằng
            Chúng tôi chỉ có thể thực hiện theo yêu cầu của Bạn khi các yêu cầu
            này khả thi về mặt kỹ thuật. Chúng tôi sẽ không chịu trách nhiệm bảo
            mật các Dữ Liệu Cá Nhân hoặc các Dữ Liệu thu được từ kết quả xử lý
            Dữ Liệu Cá Nhân của Bạn khi Bên thứ ba nhận được các dữ liệu này.
            Chúng tôi cũng có thể không cung cấp cho Bạn các Dữ Liệu Cá Nhân
            nhất định của Bạn nếu việc cung cấp này gây trở ngại cho các quyền
            khác (ví dụ khi cung cấp Dữ Liệu Cá Nhân mà Chúng tôi đã thu thập
            được có thể làm lộ thông tin của cá nhân khác).
          </li>
        </p>

        <p>
          <strong>2.</strong> Xóa Dữ Liệu
        </p>
        <p>
          Bạn có thể yêu cầu Chúng tôi xóa Dữ Liệu Cá Nhân của Bạn mà Chúng tôi
          đã thu thập được trong các trường hợp sau đây:
          <p>
            <li>
              Bạn tin rằng các Dữ liệu Cá nhân này không còn cần thiết để Chúng
              tôi nắm giữ các Dữ liệu cá nhân của Bạn;
            </li>
            <li>
              Chúng tôi xử lý Dữ Liệu Cá Nhân của Bạn mà Chúng tôi đã thu thập
              được dựa trên sự cho phép của Bạn và Bạn mong muốn rút lại sự cho
              phép của Bạn và không có bất cứ cơ sở nào khác để Chúng tôi có thể
              xử lý Dữ liệu Cá nhân này;
            </li>
            <li>
              Chúng tôi đang xử lý Dữ Liệu Cá Nhân của Bạn căn cứ vào các lợi
              ích hợp pháp của Chúng tôi và Bạn là đối tượng của việc xử lý này.
              Bạn vui lòng cung cấp các thông tin chi tiết mà Bạn cho là hợp lý
              để Chúng tôi có thể xem xét liệu đó có phải là một quyền lơi quan
              trọng hơn quyền lợi của Chúng tôi để xử lý và lưu trữ các Dữ Liệu
              Cá Nhân này;
            </li>
            <li>
              Bạn không còn muốn Chúng tôi tiếp tục sử dụng Dữ Liệu Cá Nhân của
              Bạn đã được thu thập bởi Chúng tôi để gửi các quảng cáo, các đề
              xuất đặc biệt, các chương trình bán hàng và các chương trình may
              mắn; hoặc
            </li>
            <li>
              Bạn tin tưởng rằng Dữ Liệu Cá Nhân của Bạn mà Chúng tôi thu thập
              được được xử lý một cách trái luật bởi Chúng tôi.
            </li>
          </p>
        </p>
        <p>
          Vui lòng cung cấp càng chi tiết càng tốt các lý do của Bạn để hỗ trợ
          Chúng tôi trong việc xác định liệu Bạn có cơ sở hợp lý để yêu cầu xóa
          dữ liệu hay không. Tuy nhiên, Chúng tôi có thể giữ lại Dữ Liệu Cá Nhân
          nếu có cơ sở hợp pháp theo quy định của pháp luật để Chúng tôi thực
          hiện việc này (ví dụ: thực hiện theo quy định của pháp luật của quốc
          gia sở tại – nơi công ty con, công ty thành viên của VNG đặt trụ sở
          chính, hoặc để bảo vệ Chúng tôi trước các yêu cầu pháp lý hoặc đảm bảo
          quyền tự do ngôn luận) tuy nhiên Chúng tôi sẽ thông báo cho Bạn biết
          về các trường hợp này. Bạn vui lòng lưu ý rằng sau khi xóa Dữ Liệu Cá
          Nhân, Chúng tôi có thể không có khả năng cung cấp dịch vụ như trước
          khi xóa bởi vì Chúng tôi sẽ không thể biết về các sở thích của Bạn.
        </p>
        <p>
          Bạn cần lưu ý rằng, quy định tại một số quốc gia, vùng lãnh thổ (như
          Việt Nam) quy định bắt buộc Nhà cung cấp dịch vụ như VNG buộc phải lưu
          giữ thông tin, Dữ Liệu Cá Nhân của người sử dụng trong một thời hạn
          nhất định (có thể lên đến hai (2) năm) ("Thời Hạn Lưu Trữ") kể từ thời
          điểm cuối cùng mà người sử dụng sử dụng dịch vụ; Bạn đồng ý rằng,
          trong trường hợp này thì các quy định của các quốc gia, vùng lãnh thổ
          nêu trên sẽ được ưu tiên áp dụng; do đó, yêu cầu xóa Dữ Liệu Cá Nhân
          của Bạn sẽ chỉ có thể thực hiện sau khi kết thúc Thời Hạn Lưu Trữ nêu
          trên.
        </p>
        <p>
          <strong>3.</strong> Lưu Giữ Dữ Liệu Cá Nhân
        </p>

        <p>
          Chúng tôi lưu giữ Dữ Liệu Cá Nhân của Bạn miễn là các Dữ Liệu Cá Nhân
          này cần thiết để Chúng tôi cung cấp dịch vụ hoặc sản phẩm cho Bạn,
          hoặc theo yêu cầu hoặc được cho phép theo quy định của pháp luật,
          chẳng hạn như các quy định của pháp luật về trò chơi điện tử trên
          mạng, Thuế, Kế toán. Nếu Bạn thực hiện yêu cầu xóa bỏ Dữ Liệu Cá Nhân
          của Bạn và Chúng tôi xác định rằng đây là yêu cầu có căn cứ hợp lý,
          Chúng tôi sẽ cố gắng xóa Dữ Liệu Cá Nhân của Bạn trong vòng 30 ngày kể
          từ ngày Bạn yêu cầu và kết thúc Thời Hạn Lưu Trữ nêu trên, hoặc thông
          báo cho Bạn nếu việc này cần thêm nhiều thời gian hơn.
        </p>
        <p>
          <strong>4.</strong> Phản Đối Chống Lại Các Chương Trình Bán Hàng Dựa
          Trên Hồ Sơ Khách Hàng Và Khách Hàng Mục Tiêu
        </p>

        <p>
          Tại bất kỳ thời điểm nào Bạn cũng có quyền phản đối việc xử lý Dữ Liệu
          Cá Nhân của Bạn để gửi cho Bạn các chương trình quảng cáo, các đề xuất
          đặc biệt và các tin nhắn bán hàng, bao gồm việc Chúng tôi xây dựng Hồ
          sơ khách hàng cho các mục đích này và Chúng tôi sẽ dừng việc xử lý các
          Dữ Liệu Cá Nhân của Bạn để phục vụ cho các mục đích này. Bạn vui lòng
          cung cấp cho Chúng tôi các t tôi xác định liệu Bạn có cơ sở hợp pháp
          để phản đối hay không. Tuy nhiên, Chúng tôi có thể giữ lại Dữ Liệu Cá
          Nhân của Bạn nếu có các cơ sở hợp pháp để Chúng tôi thực hiện việc
          này.
        </p>
        <p>
          <strong>5.</strong> Hạn Chế Xử Lý Dữ Liệu Cá Nhân Chỉ Để Lưu Trữ
        </p>
        <p>
          Bạn có quyền yêu cầu rằng Chúng tôi giới hạn việc xử lý Dữ Liệu Cá
          Nhân của Bạn mà Chúng tôi đã có chỉ cho mục đích lưu trữ, khi:
        </p>
        <p>
          <li>
            Bạn tin rằng Dữ liệu Cá nhân của Bạn là không chính xác, trong
            khoảng thời gian cần thiết để Chúng tôi xác minh liệu Dữ Liệu Cá
            Nhân của Bạn có chính xác hay không.
          </li>
          <li>
            Chúng tôi muốn xóa Dữ Liệu Cá Nhân của Bạn nhưng Bạn muốn Chúng tôi
            chỉ Lưu trữ;
          </li>
          <li>
            Chúng tôi muốn xóa Dữ Liệu Cá Nhân của Bạn do nó không còn cần thiết
            cho mục đích của Chúng tôi nhưng Bạn yêu cầu lưu trữ để thiết lập,
            thực hiện hoặc chống lại các yêu cầu pháp lý; hoặc
          </li>
          <li>
            Bạn phản đối Chúng tôi xử lý Dữ Liệu Cá Nhân của Bạn trên cơ sở các
            lợi ích hợp pháp của Chúng tôi và Bạn muốn Chúng tôi chấm dứt việc
            xử lý Dữ Liệu Cá Nhân khi Chúng tôi xác định liệu có quan trọng
            trong việc xử lý và giữ lại các Dữ Liệu Cá Nhân này.
          </li>
        </p>
        <p>
          <strong>6.</strong> Chuyển Dữ Liệu Cá Nhân Ra Ngoài EU
        </p>

        <p>
          Dữ Liệu Cá Nhân của Bạn có thể được chuyển ra ngoài EU. Chúng tôi sẽ
          thực hiện các bước cần thiết và thích hợp để đảm bảo rằng Người nhận
          Dữ Liệu Cá Nhân của Bạn sẽ có trách nhiệm bảo mật và Chúng tôi thực
          hiện các biện pháp chẳng hạn như các quy định trong Hợp đồng thích hợp
          để đảm bảo những người nhận dữ liệu được chuyển giao bảo vệ và xử lý
          Dữ Liệu Cá Nhân của Bạn tuân thủ tất cả các luật về bảo vệ dữ liệu cá
          nhân.
        </p>
        <p>
          <strong>7.</strong> Thông Tin Trẻ Em
        </p>

        <p>
          Các Dịch Vụ này không dành cho trẻ em dưới 16 tuổi hiện đang sinh sống
          tại EU. Chúng tôi không cố tình thu thập hay lưu giữ bất kỳ dữ liệu cá
          nhân hay thông tin không nhận dạng cá nhân nào của bất kỳ ai dưới 16
          tuổi đang sinh sống tại EU, bất kỳ phần nào của Trang Web, Trò Chơi
          của Chúng tôi hoặc Các Dịch Vụ khác cũng không dành cho trẻ em dưới 16
          tuổi đang sinh sống tại EU. Chúng tôi sẽ khóa bất kỳ tài khoản nào chỉ
          được sử dụng bởi đối tượng trẻ em như vậy và sẽ gỡ và/hoặc xóa bất kỳ
          dữ liệu cá nhân nào mà Chúng tôi cho là đã được gửi bởi bất kỳ trẻ em
          nào dưới 16 tuổi đang sinh sống tại EU.
        </p>
        <p>
          <strong>
            Điều 14. NƠI LƯU TRỮ DỮ LIỆU CÁ NHÂN VÀ CHUYỂN GIAO SANG QUỐC GIA
            KHÁC
          </strong>
        </p>
        <p>
          <strong>1.</strong> VNG lưu trữ Dữ Liệu Cá Nhân của Bạn trên toàn cầu
          và chia sẻ với các công ty trong VNG Group để thực hiện các hoạt động
          được quy định theo Chính Sách này. VNG có thể giao kết hợp đồng thứ
          cấp để xử lý, hoặc chia sẻ dữ liệu cá nhân của Bạn với các bên thứ ba
          đặt tại các quốc gia nằm ngoài quốc gia của Bạn. Do đó, Dữ Liệu Cá
          Nhân của Bạn, có thể phải tuân thủ pháp luật về quyền riêng tư có sự
          khác biệt so với pháp luật về quyền riêng tư tại quốc gia của Bạn.
        </p>
        <p>
          <strong>2.</strong> Đối với Các Dịch Vụ được cung ứng cho thị trường
          Việt Nam, bởi VNG và/hoặc các công ty con, công ty liên kết, công ty
          thành viên của VNG có trụ sở chính đặt tại Việt Nam thì Dữ Liệu Cá
          Nhân của Bạn sẽ được lưu trữ tại Việt Nam theo đúng quy định của pháp
          luật Việt Nam.
        </p>
        <p>
          <strong>3.</strong> Đối với các Dữ Liệu Cá Nhân của người dùng EU thì
          sẽ được lưu trữ, chuyển giao theo đúng quy định tại Điều 13 của Chính
          Sách này.
        </p>
        <p>
          <strong>
            Điều 15. THẮC MẮC, QUAN NGẠI HOẶC KHIẾU NẠI? LIÊN HỆ VỚI VNG
          </strong>
        </p>
        <p>
          Nếu Bạn có bất kỳ thắc mắc hoặc câu hỏi nào về Chính Sách này, vui
          lòng liên hệ với Chúng tôi tại{" "}
          <a href="https://zagoo.vn/support">
            <strong>https://zagoo.vn/support</strong>
          </a>{" "}
          hoặc email hỗ trợ:{" "}
          <a href="mailto: hotro@zagoo.vn">
            <strong>hotro@zagoo.vn</strong>
          </a>
        </p>
      </div>
    </div>
  );
};
