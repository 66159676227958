import { Loading } from "components/Loading/Loading";
import { FC, useEffect } from "react";
import { Container } from "./Container";
import { checkShowCookieConsent } from "utils/common";

const NewsDetailsIndex: FC = () => {
  useEffect(() => {
    checkShowCookieConsent();
  }, []);
  
  return (
    <div>
      <Loading />
      <Container></Container>
    </div>
  );
};

export default NewsDetailsIndex;
