import { FC } from "react";
import { Link } from "react-router-dom";

const NewsItem: FC<{
  url: string;
  image: string;
  title: string;
  date: string;
  desc: string;
}> = ({ url, image, title, date, desc }) => {
  const imgIco = require("../assets/img/title_news_icon.png");

  return (
    // flex-item basis-1/4
    // , maxWidth: 'calc(30% + 10%/6 - 16px)'
    // <div className="lg:flex-col m-[18px]" style={{flex: '1 0 25%', maxWidth: 'max(calc(30% + 10%/6 - 16px),25%)'}}>
    <div className="group p-2">
      <Link to={url}>
        <img
          className="group-hover:scale-105 transition  ease-in-out  w-full rounded sm:h-120 image"
          src={image}
          alt="img"
        />
      </Link>
      <div className="flex items-center mt-3 justify-between">
        <div className="flex items-center">
          <img src={imgIco} alt="Title News Icon" className="w-4 h-4" />
          <Link to={url} className="news-title  ml-2 font-bold text-lg">
            {title}
          </Link>
        </div>
        <span className="text-xs font-bold" style={{ color: "#545B78" }}>
          {date}
        </span>
      </div>
      <span
        className="w-full overflow-hidden text-ellipsis font-normal leading-5 mt-[6px]"
        style={{
          color: "#545B78",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
          wordBreak: "break-all",
          MozBoxOrient: "vertical",
          boxOrient: "vertical",
          lineClamp: 2,
        }}
      >
        {desc}
      </span>
    </div>
  );
};

export default NewsItem;
