import { KHO_GAME } from "helper/constants";
import { FC } from "react";
import "./style.css";
export const KhoGame: FC = () => {
  return (
    <div className="body">
      <div className="content-w size-md">
        <div className="gameStorage" id="gameStorage">
          <div className="title my-3 font-bold">KHO GAME HẤP DẪN</div>
          <div className="games">
            {KHO_GAME.map((game, idx) => {
              return (
                <a
                  key={idx}
                  className="itemGame"
                  target="_blank"
                  href={game.url}
                >
                  <div className="wrap">
                    <div className="thumb">
                      <span
                        className="aspectRatio aspectRatio--16-9"
                        style={{
                          background: `url(${game.logo}) center no-repeat`,
                          backgroundSize: "cover",
                        }}
                      ></span>
                    </div>
                    <div className="meta">
                      <div className="bl blLeft">
                        <span>{game.name}</span>
                      </div>
                      <div className="bl blRight">
                        <div className="bl-action">
                          <div className="action-w">
                            <div className="btn btn3 size-sm">Chơi ngay</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
